import moment from "moment";
import { ProviderAccountModel } from "src/api/provider/provider-types";
import { BaseButton } from "src/components";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import { YmdFormat } from "src/utils";

type Props = {
  providerAccount?: Array<ProviderAccountModel>;
  onEdit?: () => void;
};
const tableHeaderWidth = {
  minmax120: "120",
  minmax160: "160",
  minmax200: "200",
};

const ProviderAccountDetail = ({ providerAccount, onEdit }: Props) => {
  return (
    <article className="contents-container__wrap-article">
      <div className="flex-center-between pb20">
        <BaseSectionTitle title={"계좌 정보"} className="pb0" />
        <BaseButton title="수정" className="color-white" onClick={onEdit} />
      </div>
      <section className="contents-container__1200">
        {/* {defaultValues.providerAccountList?.map( */}
        <section className="contents-container__1200 account-table">
          <table className="inner-table" width="840">
            <thead>
              <tr className="border-bottom border-top">
                <th className={`minmax${tableHeaderWidth.minmax120}`}>
                  <span>용도</span>
                </th>
                <th className={`minmax${tableHeaderWidth.minmax120}`}>
                  <span>은행</span>
                </th>
                <th className={`minmax${tableHeaderWidth.minmax200}`}>
                  <span>계좌번호</span>
                </th>
                <th className={`minmax${tableHeaderWidth.minmax120}`}>
                  <span>예금주</span>
                </th>
                <th className={`minmax${tableHeaderWidth.minmax120}`}>
                  <span>연동 여부</span>
                </th>
                <th className={`minmax${tableHeaderWidth.minmax200}`}>
                  <span>최근 연동 상태</span>
                </th>
                <th className={`minmax${tableHeaderWidth.minmax160}`}>
                  <span>최근 연동일시</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {providerAccount && providerAccount.length > 0 ? (
                providerAccount.map((account: ProviderAccountModel, accountIdx: number) => {
                  return (
                    <tr key={account.accountId} className={` ${account.isDeleted === true && "d-none"} text-center`}>
                      <td width={`${tableHeaderWidth.minmax120}`}>
                        {account.accountType === "DEPOSIT_ACCOUNT" ? (
                          <span className="font13">보증금</span>
                        ) : account.accountType === "RENT_ACCOUNT" ? (
                          <span className="font13">임대료</span>
                        ) : (
                          <span className="font13">관리비</span>
                        )}
                      </td>

                      <td width={`${tableHeaderWidth.minmax120}`}>
                        <span>{account.bankName || ""}</span>
                      </td>

                      <td width={`${tableHeaderWidth.minmax200}`}>
                        <span>{account.accountNumber}</span>
                      </td>

                      <td width={`${tableHeaderWidth.minmax200}`}>
                        <span>{account.accountHolder}</span>
                      </td>
                      {/* 연동 여부 */}
                      <td width={`${tableHeaderWidth.minmax120}`}>
                        <>
                          {account.syncCode ? (
                            <span>{account.syncCode > 0 ? "연동 성공" : account.syncCode < 0 ? "연동 실패" : "-"}</span>
                          ) : (
                            <span>-</span>
                          )}
                        </>
                      </td>
                      <td width={`${tableHeaderWidth.minmax160}`}>
                        <span>{account.syncMessage || "-"}</span>
                      </td>
                      <td width={`${tableHeaderWidth.minmax200}`}>
                        <span>{account.syncDate ? moment(account.syncDate).format("YYYY.MM.DD HH:mm:ss") : "-"}</span>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="font14 text-center">
                  <td colSpan={6}>등록된 계좌 정보가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </section>
      </section>
    </article>
  );
};

export default ProviderAccountDetail;
