import { useMemo, useState } from "react";
import { useApiOperation } from "src/api/hooks";
import { checkPayletterApiConfirm, editProviderPayletterAsync } from "src/api/provider/provider-api";
import { ProviderDetailModel, ProviderPayletterModel } from "src/api/provider/provider-types";
import { Modal } from "src/api/public-types";
import { BaseModal } from "src/components";
import AutoPayletterDetail from "src/pages/provider/detail/basicInfoDetail/components/AutoPayletterDetail";
import BasicPayletterDetail from "src/pages/provider/detail/basicInfoDetail/components/BasicPayletterDetail";
import AutoPayletter from "../../components/AutoPayletter";
import BasicPayletter from "../../components/BasicPayletter";

type Props = {
  providerId: number;
  providerDetail?: ProviderDetailModel;
  providerDetailApi: (id: string) => Promise<void>;
};

const PayletterSection = ({ providerId, providerDetail, providerDetailApi }: Props) => {
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  const [isPayletterEdit, setIsPayletterEdit] = useState({
    isBasicPayletterEdit: false,
    isAutoPayletterEdit: false,
  });

  // 페이레터 수정
  const { executeAsync: editProviderPayletter } = useApiOperation(editProviderPayletterAsync);

  const { executeAsync: checkPayletterApi } = useApiOperation(checkPayletterApiConfirm);

  const payletterList = useMemo(() => providerDetail?.payletterList || [], [providerDetail]);

  const payletterApiCheck = async (payletter: ProviderPayletterModel) => {
    if (!payletter) {
      setAlertModal({ isOpen: true, message: "페이레터 정보를 저장하세요." });
      return;
    }

    if (providerId) {
      const response = await checkPayletterApi({ providerId, clientType: payletter.clientType });

      if (response.status >= 200 || response.status <= 299) {
        const result = response.data.data;
        await providerDetailApi(String(providerId));
        console.log("result", result);
        //
        // 에러 메세지 있을 시 팝업 여부 확인 필요
        if (result.verifiedError.verifiedPaymentKeyMessage) {
          setAlertModal({
            isOpen: true,
            message: result.verifiedError.verifiedPaymentKeyMessage,
            payload: "error",
          });
        }

        if (result.verifiedError.verifiedSearchKeyMessage) {
          setAlertModal({
            isOpen: true, //
            message: result.verifiedError.verifiedSearchKeyMessage,
            payload: "error",
          });
        }
      }
    }
  };

  return (
    <>
      {/* 일반결제 */}
      {isPayletterEdit.isBasicPayletterEdit ? (
        <BasicPayletter
          providerId={providerId}
          payletter={payletterList?.find((payletter) => payletter.clientType === "NORMAL")}
          autoPayletter={payletterList?.find((payletter) => payletter.clientType === "AUTO")}
          editProviderPayletter={editProviderPayletter}
          providerDetailApi={providerDetailApi}
          isEdit={() => setIsPayletterEdit((prev) => ({ ...prev, isBasicPayletterEdit: false }))}
        />
      ) : (
        <BasicPayletterDetail
          payletter={payletterList.find((payletter) => payletter.clientType === "NORMAL")}
          isEdit={() => setIsPayletterEdit((prev) => ({ ...prev, isBasicPayletterEdit: true }))}
          payletterApiCheck={payletterApiCheck}
        />
      )}

      {/* 자동결제 */}
      {isPayletterEdit.isAutoPayletterEdit ? (
        <AutoPayletter
          providerId={providerId}
          providerDetailApi={providerDetailApi}
          editProviderPayletter={editProviderPayletter}
          payletter={payletterList?.find((payletter) => payletter.clientType === "AUTO")}
          basicPayletter={payletterList?.find((payletter) => payletter.clientType === "NORMAL")}
          isEdit={() => setIsPayletterEdit((prev) => ({ ...prev, isAutoPayletterEdit: false }))}
        />
      ) : (
        <AutoPayletterDetail
          payletter={payletterList.find((payletter) => payletter.clientType === "AUTO")}
          basicPayletter={payletterList.find((payletter) => payletter.clientType === "NORMAL")}
          isEdit={() => setIsPayletterEdit((prev) => ({ ...prev, isAutoPayletterEdit: true }))}
          payletterApiCheck={payletterApiCheck}
        />
      )}

      <BaseModal
        isOpen={alertModal.isOpen}
        btnRightTitle="확인"
        onClick={() => {
          setAlertModal({ isOpen: false, payload: "" });
        }}
      >
        {alertModal.payload === "error" && (
          <div>
            <p>{alertModal.message}</p>
          </div>
        )}
      </BaseModal>
    </>
  );
};

export default PayletterSection;
