import { Cell } from "react-table";
import { ProviderModel } from "src/api/provider/provider-types";
import { BaseTooltip } from "src/components";
import DetailLink from "src/components/DetailLink";
import { PagePath } from "src/pages/product/details";
import { companyRegistrationHypen } from "src/utils";

const ProviderColumns = [
  {
    Header: "id",
    accessor: "providerId",
    width: 80,
    Cell: ({ row, value }: Cell<ProviderModel>) => {
      const detailPath = `${PagePath.provider.detail.replace(":id", String(row.original.providerId))}`;

      return (
        <DetailLink to={detailPath} className="text-underline">
          {value}
        </DetailLink>
      );
    },
  },
  {
    Header: "유형",
    accessor: "supplyType",
    width: 160,
    Cell: ({ row, value }: Cell<ProviderModel>) => {
      return <div>{value === "RENTAL" ? "프로바이더" : "관리처"}</div>;
    },
  },
  {
    Header: "법인명/상호",
    accessor: "providerName",
    width: 240,
    Cell: ({ row, value }: Cell<ProviderModel>) => {
      return <BaseTooltip className="w-100 text-left" contents={value || "-"} />;
    },
  },
  {
    Header: "사업자 등록번호",
    accessor: "businessRegistrationNumber",
    width: 160,
    Cell: ({ value }: Cell<ProviderModel>) => {
      return companyRegistrationHypen(value);
    },
  },
  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    width: 120,
    Cell: (props: Cell<ProviderModel>) => {
      return <BaseTooltip contents={props.value} type="date" />;
    },
  },
  {
    Header: "최종 수정자",
    accessor: "modifiedBy",
    width: 120,
    Cell: ({ value }: Cell<ProviderModel>) => {
      return <BaseTooltip contents={value} isSingleLine={true} />;
    },
  },
];

export default ProviderColumns;
