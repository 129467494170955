import { ProviderMediaFile, ProviderModel } from "src/api/provider/provider-types";
import ContractUpload from "src/pages/provider/forms/components/ContractUpload";
import { companyRegistrationHypen } from "src/utils";

type Props = {
  providerBasic?: ProviderModel;
  contractMedia?: Array<ProviderMediaFile>;
};

const ProviderBasicDetail = ({ providerBasic, contractMedia }: Props) => {
  return (
    <section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>유형</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span>{providerBasic?.supplyType === "RENTAL" ? "프로바이더" : "관리처"}</span>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>법인명/상호</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span>{providerBasic?.providerName || "-"}</span>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>사업자 등록번호</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span>{companyRegistrationHypen(String(providerBasic?.businessRegistrationNumber)) || "-"}</span>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>대표자/성명</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span>{providerBasic?.representative || "-"}</span>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>사업장 주소지</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span>{providerBasic?.address || "-"}</span>
          </div>
        </div>
      </section>

      <div className="flex-center">
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="">업태</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span>{providerBasic?.bizType || "-"}</span>
            </div>
          </div>
        </section>

        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="">종목</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span>{providerBasic?.bizClass || "-"}</span>
            </div>
          </div>
        </section>
      </div>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">사업자등록증 사본</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="pt10">
            <ContractUpload isOnlyView privateFiles={contractMedia} />
          </div>
        </div>
      </section>
    </section>
  );
};

export default ProviderBasicDetail;
