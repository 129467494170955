import qs from "qs";
import { useEffect, useState } from "react";
import { NavigateOptions, To, useNavigate as _useNavigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { globalPartnerState } from "src/recoil/partners/atom";

/*
  이 훅은 파트너 ID를 포함한 URL로 이동하는 훅입니다.
*/
export default function useNavigate() {
  const _navigate = _useNavigate();
  const partner = useRecoilValue(globalPartnerState);

  const [isNewTab, setIsNewTab] = useState(false);

  function navigate(to: To | number, options?: NavigateOptions) {
    // ============================ v.1.23 작업 (harry) ============================
    if (typeof to === "string") {
      let newTo = "";

      // list 파라미터가 있는지 확인
      const hasListParams = to.includes("list=page");

      // list 파라미터가 있는 경우
      if (hasListParams) {
        const currentPathname = to.split("?")[0];
        const currentParams = qs.parse(to.split("?")[1], { ignoreQueryPrefix: true, allowDots: true });

        const listParams: Record<string, any> = {};
        const otherParams: Record<string, any> = {};
        Object.entries(currentParams).forEach(([key, value]) => {
          if (key.startsWith("list")) {
            listParams[key] = value;
          } else {
            otherParams[key] = value;
          }
        });
        const requestParams = { ...otherParams, ...listParams };

        newTo = currentPathname + "?" + qs.stringify(requestParams, { allowDots: true });
      }

      // list 파라미터가 있으면 newTo, 없으면 to 로 이동
      const _to = newTo ? newTo : to.replace(":partnerId", String(partner?.id));
      if (isNewTab) {
        window.open(_to);
      } else {
        _navigate(_to, options);
      }

      return;
    }

    if (typeof to === "number") {
      _navigate(to);
      return;
    }

    if (isNewTab) {
      if (typeof to === "object" && to.search) {
        window.open(window.location.pathname + "?" + to.search);
      }
    } else {
      _navigate(to, options);
    }
  }
  // ====  v.1.23 작업 (harry) > naviage 에서 list 관련 파라미터 처리를 위해 toUrl 로 처리   ====

  // =========== 이전 코드 ===========
  //   if (typeof to === "string") {
  //     const _to = to.replace(":partnerId", String(partner?.id));
  //     if (isNewTab) {
  //       window.open(_to);
  //     } else {
  //       _navigate(_to, options);
  //     }

  //     return;
  //   }

  //   if (typeof to === "number") {
  //     _navigate(to);
  //     return;
  //   }

  //   if (isNewTab) {
  //     if (typeof to === "object" && to.search) {
  //       window.open(window.location.pathname + "?" + to.search);
  //     }
  //   } else {
  //     _navigate(to, options);
  //   }
  // }
  // =========== 이전 코드 ===========

  useEffect(() => {
    const handleBlur = () => {
      // console.log("blur event - window level");
      setIsNewTab(false);
    };

    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  useEffect(() => {
    const handleClickKeydown = (event: KeyboardEvent) => {
      // command Keydown
      if (event.key === "Meta" && event.metaKey) {
        // console.log("Keydown");
        setIsNewTab(true);
      }
    };

    const handleClickKeyup = (event: KeyboardEvent) => {
      // command Keyup
      if (event.key === "Meta" && event.metaKey === false) {
        // console.log("Keyup");
        setIsNewTab(false);
      }
    };

    const handleWheelClickMousedown = (event: any) => {
      if (event.button === 1) {
        setIsNewTab(true);
      }
    };

    const handleClickMouseup = (event: any) => {
      if (event.button === 1) {
        setIsNewTab(false);
      }
    };
    //등록
    //커맨드
    document.addEventListener("keydown", handleClickKeydown);
    document.addEventListener("keyup", handleClickKeyup);
    //마우스 휠
    document.addEventListener("mousedown", handleWheelClickMousedown);
    document.addEventListener("mouseup", handleClickMouseup);

    //제거
    return () => {
      //커맨드
      document.removeEventListener("keydown", handleClickKeydown);
      document.removeEventListener("keyup", handleClickKeyup);
      //마우스 휠
      document.removeEventListener("mousedown", handleWheelClickMousedown);
      document.removeEventListener("mouseup", handleClickMouseup);
    };
  }, []);

  return navigate;
}
