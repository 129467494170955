import { BaseSelect } from "src/components";
import { BuildingStructure, SelectOption } from "src/pages/building/building-types";

type Props = {
  buildingStructureTypes: Array<BuildingStructure>;
  buildingStructureOptions: Array<SelectOption>;
  setBuildingStructureTypes: Function;
};

// 건축물 용도 컴포넌트
const BuildingStructures = ({ buildingStructureTypes, buildingStructureOptions, setBuildingStructureTypes }: Props) => {
  // 추가 버튼 클릭

  const clickAddBuildingStructure = () => {
    const maxSeq = Math.max.apply(
      Math,
      buildingStructureTypes.map((o) => o.seq),
    );

    const newBuildingStructures: Array<BuildingStructure> = [
      ...buildingStructureTypes,
      {
        seq: maxSeq + 1,
        facility: undefined,
        use: undefined, //
        orderNums: buildingStructureTypes.length + 1,
      },
    ];
    setBuildingStructureTypes(newBuildingStructures);
  };

  // 삭제 버튼 클릭
  const clickDeleteBuildingStructure = (seq: number) => {
    const newBuildingStructures: Array<BuildingStructure> = buildingStructureTypes.filter(
      (buildingStructureType) => buildingStructureType.seq !== seq,
    );
    setBuildingStructureTypes(newBuildingStructures);
  };

  // 시설군 선택
  const changeBuildingFacility = (selectedValue: string, seq: number) => {
    const currentBuildingStructures: Array<BuildingStructure> = [...buildingStructureTypes];
    const newBuildingStructures: Array<BuildingStructure> = [];
    for (let i = 0; i < currentBuildingStructures.length; i++) {
      const current = currentBuildingStructures[i];
      if (current.seq === seq) {
        newBuildingStructures.push({
          id: current.id,
          seq: current.seq,
          facility: selectedValue,
          use: null, // 용도 초기화
          orderNums: current.orderNums,
        });
      } else {
        newBuildingStructures.push(current);
      }
    }
    setBuildingStructureTypes(newBuildingStructures);
  };

  // 용도 선택
  const changeBuildingStructure = (selectedValue: string, seq: number) => {
    const currentBuildingStructures: Array<BuildingStructure> = [...buildingStructureTypes];
    const newBuildingStructures: Array<BuildingStructure> = [];
    for (let i = 0; i < currentBuildingStructures.length; i++) {
      const current = currentBuildingStructures[i];
      if (current.seq === seq) {
        newBuildingStructures.push({
          id: current.id,
          seq: current.seq,
          facility: current.facility,
          use: selectedValue,
          orderNums: current.orderNums,
        });
      } else {
        newBuildingStructures.push(current);
      }
    }
    setBuildingStructureTypes(newBuildingStructures);
  };

  return (
    <>
      {buildingStructureTypes?.map((buildingStructureType: BuildingStructure, index: number) => (
        <div key={index} className={`minmax400 flex-center ${index < buildingStructureTypes.length - 1 ? "mb10" : ""}`}>
          <BaseSelect
            placeholder="시설군"
            className="mr10"
            value={buildingStructureType.facility}
            stateOptions={buildingStructureOptions}
            setStateValue={(value: string) => changeBuildingFacility(value, buildingStructureType.seq)}
          />
          <BaseSelect
            placeholder="용도"
            className="mr10"
            value={buildingStructureType.use}
            stateOptions={buildingStructureOptions.find((option: SelectOption) => option.value === buildingStructureType.facility)?.subOptions || []}
            setStateValue={(value: string) => changeBuildingStructure(value, buildingStructureType.seq)}
          />
          {index === 0 ? (
            <button type="button" className="base-add-btn" onClick={() => clickAddBuildingStructure()}></button>
          ) : (
            <button type="button" className="base-erase-btn" onClick={() => clickDeleteBuildingStructure(buildingStructureType.seq)}></button>
          )}
        </div>
      ))}
    </>
  );
};

export default BuildingStructures;
