import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useApiOperation } from "src/api/hooks";
import { checkPayletterApiConfirm } from "src/api/provider/provider-api";
import { ProviderPayletterAddModel, ProviderPayletterModel } from "src/api/provider/provider-types";
import { BaseButton, BaseInput, BaseModal, BaseToggle, BaseTooltip } from "src/components";
import { Modal } from "src/pages/product/product-types";
import { useToast } from "src/recoil/toast/hook";
import AutoPayletterStatus from "../../detail/basicInfoDetail/components/AutoPayletterStatus";

type Props = {
  providerId: number;
  payletter?: ProviderPayletterModel;
  providerDetailApi: Function;
  editProviderPayletter: Function;
  basicPayletter?: ProviderPayletterModel;
  isEdit: () => void;
};

const AutoPayletter = ({ providerId, payletter, providerDetailApi, editProviderPayletter, basicPayletter, isEdit }: Props) => {
  // 로딩바

  // 토스트
  const { openToast } = useToast();

  // 카드 자동결제 토글버튼
  const [isAutoPayletter, setIsAutoPayletter] = useState(true);

  // 페이레터 등록/수정 api data
  const [submitData, setSubmitData] = useState<ProviderPayletterAddModel>();

  // 저장 컨펌 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // 경고창 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // 페이레터 api 검증

  const { executeAsync: checkPayletterApi } = useApiOperation(checkPayletterApiConfirm);

  const defaultValues = useMemo(() => {
    const providerPostData: ProviderPayletterAddModel = {
      payletterList: {
        clientId: "",
        paymentKey: "",
        searchKey: "",
        clientType: "AUTO",
        isVerifiedPaymentKey: false,
        isVerifiedSearchKey: false,
      },
    };

    return providerPostData;
  }, [providerId, payletter]);

  const { control, setValue, register, handleSubmit, watch } = useForm<ProviderPayletterAddModel>({
    defaultValues,
  });

  //

  useEffect(() => {
    if (!basicPayletter && isAutoPayletter) {
      isEdit();
    }

    if (payletter) {
      setValue("payletterList", payletter);
    }
  }, [payletter]);

  // 페이레터 검증
  const payletterApiCheck = async () => {
    const clientId = payletter?.clientId;
    const paymentKey = payletter?.paymentKey;
    const searchKey = payletter?.searchKey;

    if (!clientId || !paymentKey || !searchKey) {
      setAlertModal({ isOpen: true, message: "페이레터 정보를 저장하세요." });
      return;
    }

    if (providerId) {
      const response = await checkPayletterApi({ providerId, clientType: "AUTO" });

      if (response.status >= 200 || response.status <= 299) {
        const result = response.data.data;

        // 에러 메세지 있을 시 팝업 여부 확인 필요

        if (result.verifiedError.verifiedAutoPaymentKeyMessage) {
          setAlertModal({
            isOpen: true,
            message: result.verifiedError.verifiedAutoPaymentKeyMessage,
            payload: "error",
          });
        }

        if (result.verifiedError.verifiedAutoSearchKeyMessage) {
          setAlertModal({
            isOpen: true,
            message: result.verifiedError.verifiedAutoSearchKeyMessage,
            payload: "error",
          });
        }
        providerDetailApi(providerId);
      }
    }
  };

  //
  // 페이레터 유효성 검사
  const formValidation = useCallback(() => {
    // 카드결제 지원 (payletter)
    if (isAutoPayletter) {
      register("payletterList.clientId", {
        validate: (value: string) => {
          if (!value) return "가맹점 아이디를 입력해주세요.";
          const alphanumericRegex = /^[A-Za-z0-9]+$/;
          if (!alphanumericRegex.test(value)) {
            return "가맹점 아이디는 알파벳과 숫자로만 입력할 수 있습니다.";
          }
        },
      });
      register("payletterList.paymentKey", {
        required: { value: true, message: "결제 API Key를 입력해주세요." },
      });
      register("payletterList.searchKey", {
        required: { value: true, message: "조회 API Key를 입력해주세요." },
      });
    }
  }, [isAutoPayletter, register]);

  useEffect(() => {
    formValidation();
  }, [formValidation]);

  //
  // 페이레터 자동결제 수정 api
  const updateAutoPayletterApi = async (data?: ProviderPayletterAddModel, reset?: boolean) => {
    let editData = isAutoPayletter ? data?.payletterList : { ...data?.payletterList, isDeleted: true };

    const response = await editProviderPayletter({ providerId, payletterList: [editData] });
    if (response.status >= 200 || response.status <= 299) {
      await providerDetailApi(response.data.data.providerId);
      setConfirmModal({ isOpen: false });
      isEdit();
      openToast({ content: `정상적으로 수정되었습니다.` });
    }
  };

  //  유효성 검사후 저장
  const onSubmit = async (data?: ProviderPayletterAddModel, e?: any) => {
    e.preventDefault();
    setSubmitData(data);
    setConfirmModal({ isOpen: true, message: isAutoPayletter ? "저장하시겠습니까?" : "등록된 신용카드 자동결제를 해제하시겠습니까?" });
  };

  const onError = useCallback((errors: any, e?: any) => {
    console.log("onError errors", errors);
    return false;
  }, []);

  //
  // 카드 자동결제 토글 클릭시 작동
  const handleToggleFunction = (checked: boolean) => {
    setValue("payletterList.isDeleted", checked);
    setIsAutoPayletter(checked);
  };

  return (
    <article className="contents-container__wrap-article">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__sub-title flex-column">
          <div className="flex-center-between w-100">
            <div className="flex-center">
              <h2 className="">신용카드 자동결제</h2>
              <div className="ml10 mr15">
                <BaseTooltip touchIcon="QUESTION" className=" ">
                  <ul className="pl20">
                    <li className="mb5">{`일반결제는 한 번의 거래로 결제가 완료되는 방식입니다.`}</li>
                    <li>
                      신용카드 일반결제를 제공하려면{" "}
                      <a href="https://www.payletter.com/ko/service/domestic" target={"_blank"} className="text-blue px5" rel="noreferrer">
                        페이레터
                      </a>
                      의 전자결제서비스에 가입 후 연동을 완료해주세요.
                    </li>
                  </ul>
                </BaseTooltip>
              </div>
              <BaseToggle
                onChange={(checked: boolean) => {
                  handleToggleFunction(checked);
                }}
                checked={isAutoPayletter}
              />
            </div>
            <div className="flex-center">
              <BaseButton title="취소" className="color-white mr10" onClick={isEdit} />
              <BaseButton
                type="submit"
                title="저장" //
                disabled={basicPayletter ? false : true}
              />
            </div>
          </div>
        </div>
        {isAutoPayletter && (
          <>
            <div className="mb10">
              <div className="index-tab">
                <span>자동결제 API 연동 설정</span>
              </div>
              <div className="border-gray py16 pr16 px20">
                <section className="contents-container__grid ">
                  <div className="contents-container__grid-index">
                    <p className="required">가맹점 아이디</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax400">
                      <Controller
                        control={control}
                        name={`payletterList.clientId`}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                          return (
                            <BaseInput
                              onChange={onChange}
                              value={value}
                              name={name}
                              errorText={error && error.message}
                              placeholder="페이레터에서 발급받은 Merchant ID를 입력해주세요"
                            />
                          );
                        }}
                      ></Controller>
                    </div>
                  </div>
                </section>

                <section className="contents-container__grid ">
                  <div className="contents-container__grid-index">
                    <p className="required">결제 API Key</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax400">
                      <Controller
                        control={control}
                        name={`payletterList.paymentKey`}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                          return (
                            <BaseInput
                              onChange={onChange}
                              value={value}
                              name={name}
                              errorText={error && error.message}
                              type="password"
                              placeholder="페이레터에서 발급받은 Payment API Key를 입력해주세요"
                            />
                          );
                        }}
                      ></Controller>
                    </div>
                  </div>
                </section>

                <section className="contents-container__grid ">
                  <div className="contents-container__grid-index">
                    <p className="required">조회 API Key</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax400">
                      <Controller
                        control={control}
                        name={`payletterList.searchKey`}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                          return (
                            <BaseInput
                              onChange={onChange}
                              value={value}
                              name={name}
                              errorText={error && error.message}
                              type="password"
                              placeholder="페이레터에서 발급받은 Search API Key를 입력해주세요"
                            />
                          );
                        }}
                      ></Controller>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </>
        )}
      </form>
      <div className="mb10">
        <AutoPayletterStatus payletter={payletter} payletterApiCheck={payletterApiCheck} />
      </div>
      <BaseModal
        isOpen={confirmModal.isOpen}
        btnRightTitle="확인"
        btnLeftTitle="취소"
        className="pa20"
        title={confirmModal.message}
        onClose={() => setConfirmModal({ isOpen: false })}
        onClick={() => {
          (submitData || confirmModal.payload) && updateAutoPayletterApi(submitData, confirmModal.payload);
        }}
      ></BaseModal>
      <BaseModal
        isOpen={alertModal.isOpen}
        btnRightTitle="확인"
        title={!alertModal.payload ? alertModal.message : ""}
        onClick={() => {
          setAlertModal({ isOpen: false, payload: "" });
        }}
      >
        {alertModal.payload === "error" && (
          <div>
            <p className="font18 font-weight-semibold text-gray900">결제 검증에 실패하였습니다.</p>
            <p className="my20">검증 중 문제가 발생하였습니다.</p>
            <p className="pa8 text-left" style={{ backgroundColor: "#F2F4F6", maxHeight: 250, overflow: "hidden", textOverflow: "ellipsis" }}>
              {alertModal.message}
            </p>
          </div>
        )}
      </BaseModal>
    </article>
  );
};

export default AutoPayletter;
