import { useMemo } from "react";
import { ProviderContactModel } from "src/api/provider/provider-types";
import ProviderContactTable from "../../contactInfoDetail/components/ProviderContactTable";
import { BaseTooltip } from "src/components";

type Props = { providerContacts?: Array<ProviderContactModel> };

const ProviderContactDetail = ({ providerContacts }: Props) => {
  const csContact = useMemo(() => providerContacts?.find((item) => item.assignedWorkType === "CS_PROVIDE"), [providerContacts]);
  const taxContact = useMemo(() => providerContacts?.find((item) => item.assignedWorkType === "TAX_PROVIDE"), [providerContacts]);

  return (
    <div>
      <article className="contents-container__wrap-article">
        <div className="contents-container__sub-title">
          <h2>연락처 정보</h2>
        </div>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <div className="flex-center">
              <p>문의 접수 전화번호</p>
              <BaseTooltip className="ml2" touchIcon="QUESTION">
                <ul className="small-bullet pl15">
                  <li className="mb3">
                    <span>문의 접수를 위해 노출되는 연락처는 개인 휴대폰 번호를 사용할 수 없습니다.</span>
                  </li>
                  <li>
                    <span>해당 정보는 Taap, 알림톡, 이메일에 표시됩니다.</span>
                  </li>
                </ul>
              </BaseTooltip>
            </div>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <p>{csContact?.contact}</p>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>문의 접수 이메일</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <p>{csContact?.email}</p>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>세금계산서 이메일</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <p>{taxContact?.email}</p>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>세금계산서 전화번호</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <p>{taxContact?.contact || "-"}</p>
            </div>
          </div>
        </section>
      </article>

      {/* 계약 진행 담당 */}
      <ProviderContactTable
        providerContacts={providerContacts?.filter((item) => item.assignedWorkType === "NORMAL_PROVIDE")}
        type={"NORMAL_PROVIDE"}
      />
    </div>
  );
};

export default ProviderContactDetail;
