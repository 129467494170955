import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import {
  AddProviderAccountResponse,
  PayletterCheckResponse,
  PopbillCheckResponse,
  ProviderAccountModel,
  ProviderBasicModel,
  ProviderContactModel,
  ProviderPayletterAddModel,
  ProviderPayletterModel,
  ProviderPopbillAddModel,
} from "src/api/provider/provider-types";
import ApiPath from "src/api/apiPath.json";
import { ApiResponse } from "../public-types";
import {
  AccountHolderModel,
  addProviderContactResponse,
  BankCodeList,
  ProviderBasicInfoAddModel,
  ProviderDetailModel,
  ProviderListQueryParams,
  ProviderModel,
} from "./provider-types";

export interface ProviderListData {
  content: Array<ProviderModel>;
}

// TODO: path API path 밑으로 넣기

// RCC10. 정산정보 목록 조회
export const getProviderList = async (
  axios: AxiosInstance,
  params?: ProviderListQueryParams,
): Promise<AxiosResponse<ApiResponse<ProviderListData>>> => {
  const path = ApiPath.provider.basicInfo.list;
  return await axios.get<ApiResponse<ProviderListData>>(path, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};

//RCC20. 정산정보 기본정보 등록

export const postProviderBasicInfoAsync = async (
  axios: AxiosInstance,
  params?: ProviderBasicInfoAddModel,
): Promise<AxiosResponse<ApiResponse<{ providerId: number }>>> => {
  return await axios.post(ApiPath.provider.basicInfo.add, params);
};

// RCC13. 프로바이더 기본정보 수정
export const editProviderBasicInfoAsync = async (
  axios: AxiosInstance,
  params?: ProviderBasicInfoAddModel,
): Promise<AxiosResponse<ApiResponse<{ providerId: number }>>> => {
  return await axios.patch(ApiPath.provider.basicInfo.edit.replace("{providerId}", String(params?.providerId)), params);
};

// 프로바이더 연락처 등록

export const postProviderContactAsync = async (
  axios: AxiosInstance,
  params?: {
    providerId: number;
    providerContactList: Array<ProviderContactModel>;
  },
): Promise<AxiosResponse<ApiResponse<addProviderContactResponse>>> => {
  return axios.post(ApiPath.provider.contact.update.replace("{providerId}", String(params?.providerId)), {
    providerContactList: params?.providerContactList,
  });
};

// 프로바이더 연락처 수정

export const editProviderContactAsync = async (
  axios: AxiosInstance,
  params?: {
    providerId: number;
    providerContactList: Array<ProviderContactModel>;
  },
): Promise<AxiosResponse<ApiResponse<addProviderContactResponse>>> => {
  return axios.patch(ApiPath.provider.contact.update.replace("{providerId}", String(params?.providerId)), {
    providerContactList: params?.providerContactList,
  });
};

// 프로바이더 계좌 등록
export const addProviderAccountAsync = async (
  axios: AxiosInstance,
  params?: {
    providerAccountList: Array<ProviderAccountModel>;
    // providerAccountList: ProviderAccountAddModel;
  },
): Promise<AxiosResponse<ApiResponse<AddProviderAccountResponse>>> => {
  let providerId = 0;
  if (params?.providerAccountList) {
    providerId = params?.providerAccountList[0].providerId || 0;
  }

  return axios.post(ApiPath.provider.account.update.replace("{providerId}", String(providerId)), {
    providerAccountList: params?.providerAccountList,
  });
};

// 프로바이더 계좌 수정
export const editProviderAccountAsync = async (
  axios: AxiosInstance,
  params?: {
    providerAccountList: Array<ProviderAccountModel>;
  },
): Promise<AxiosResponse<ApiResponse<{ providerId: number }>>> => {
  let providerId = 0;
  if (params?.providerAccountList) {
    providerId = params?.providerAccountList[0].providerId || 0;
  }

  return axios.patch(ApiPath.provider.account.update.replace("{providerId}", String(providerId)), {
    providerAccountList: params?.providerAccountList,
  });
};

// 프로바이더 페이레터 등록

export const postProviderPayletterAsync = async (
  axios: AxiosInstance,
  params?: {
    providerId?: number;
    payletterList?: ProviderPayletterModel;
  },
): Promise<AxiosResponse<ApiResponse<{ providerId: number }>>> => {
  console.log("post params", params);
  return axios.post(ApiPath.provider.payletter.update.replace("{providerId}", String(params?.providerId)), {
    payletterList: [params?.payletterList],
  });
};

// 프로바이더 페이레터 수정

export const editProviderPayletterAsync = async (
  axios: AxiosInstance,
  params?: {
    providerId?: number;
    payletterList?: ProviderPayletterModel[];
  },
): Promise<AxiosResponse<ApiResponse<{ providerId: number }>>> => {
  return axios.patch(ApiPath.provider.payletter.update.replace("{providerId}", String(params?.providerId)), {
    payletterList: params?.payletterList,
  });
};

/**
 *
 * @Api RCC18. 정산정보 페이레터 API 검증 확인
 * @param axios
 * @param params
 * @returns 페이레터 api 검증 결과
 */
export const checkPayletterApiConfirm = async (
  axios: AxiosInstance,
  params?: {
    providerId: number;
    clientType: "NORMAL" | "AUTO" | "EASY";
  },
): Promise<AxiosResponse<ApiResponse<PayletterCheckResponse>>> => {
  return await axios.get(ApiPath.provider.payletter.check.replace("{providerId}", String(params?.providerId)), {
    params: {
      clientType: params?.clientType,
    },
  });
};

// 프로바이더 팝빌 등록

export const postProviderPopbillAsync = async (
  axios: AxiosInstance,
  params?: ProviderPopbillAddModel,
  // params?: {
  //   providerId: number;
  //   providerPopbill: ProviderPopbillModel;
  // },
): Promise<AxiosResponse<ApiResponse<{ providerId: number }>>> => {
  return axios.post(ApiPath.provider.popbill.update.replace("{providerId}", String(params?.providerPopbill?.providerId)), params);
};

// 프로바이더 팝빌 수정

export const editProviderPopbillAsync = async (
  axios: AxiosInstance,
  params?: ProviderPopbillAddModel,
): Promise<AxiosResponse<ApiResponse<{ providerId: number }>>> => {
  return axios.patch(ApiPath.provider.popbill.update.replace("{providerId}", String(params?.providerPopbill?.providerId)), params);
};

//RCC11. 프로바이더 상세

export const providerDetailAsync = async (
  axios: AxiosInstance,
  params?: {
    providerId: string;
  },
): Promise<AxiosResponse<ApiResponse<ProviderDetailModel>>> => {
  if (!params?.providerId) {
    throw Error("id is not found.");
  }
  const path = ApiPath.provider.detail.replace("{providerId}", params.providerId);

  return await axios.get(path);
};

// 프로바이더 은행코드 리스트 (은행목록 select)
export const getBankCodeListAsync = async (
  axios: AxiosInstance,
  params?: {
    codeGroup: string;
  },
): Promise<AxiosResponse<ApiResponse<BankCodeList>>> => {
  return await axios.get("/api/ctrl/partners/{partnerId}/ct/common/code/list", { params });
};

// 예금주 확인
export const confirmAccountHolderAsync = async (
  axios: AxiosInstance,
  params?: {
    providerId?: number;
    bankCode: string;
    accountNumber: string;
  },
): Promise<AxiosResponse<ApiResponse<AccountHolderModel>>> => {
  const path = `/api/ctrl/partners/{partnerId}/ct/popbill/acct/verify-account-holder?bankCode=${params?.bankCode}&accountNumber=${params?.accountNumber}`;
  return await axios.get(path);
};

// 팝빌 검증

export const popbillInfoCheck = async (
  axios: AxiosInstance,
  params?: {
    providerId: number;
  },
): Promise<AxiosResponse<ApiResponse<PopbillCheckResponse>>> => {
  return await axios.get(ApiPath.provider.popbill.check.replace("{providerId}", String(params?.providerId)));
};

/**
 * RCC15b. (무인증) 정산정보 기본조회
 */
export const getProviderBasicAsync = async (
  axios: AxiosInstance,
  params?: {
    providerId: string;
  },
): Promise<AxiosResponse<ApiResponse<{ provider: ProviderBasicModel }>>> => {
  if (!params?.providerId) {
    throw Error("id is not found.");
  }
  const path = ApiPath.provider.nonAuthenticated.detail.replace("{providerId}", params.providerId);

  return await axios.get(path);
};
