import React from "react";
import { ProviderAssignedWorkType, ProviderContactModel } from "src/api/provider/provider-types";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import { BULLET, formatPhoneNumber } from "src/utils";

type Props = { providerContacts?: Array<ProviderContactModel>; type: ProviderAssignedWorkType };

const ProviderContactTable = ({ providerContacts, type }: Props) => {
  console.log("providerContacts", providerContacts);
  return (
    <article className="contents-container__wrap-article ">
      <BaseSectionTitle
        title={type === "CS_PROVIDE" ? "CS/문의" : type === "NORMAL_PROVIDE" ? "계약 진행 담당" : "세금 계산서 발행"}
        className="pb10"
      />
      <div className="ml10 mb20 text-primary3 font14">
        <p>{BULLET} 계약 체결 요청, 계약 종료 안내 등과 관련된 고객 문의를 처리할 담당자의 정보입니다.</p>
        <p>{BULLET} 해당 정보는 계약 관련하여 고객과의 직접적인 소통을 위해 알림톡에 사용됩니다.</p>
      </div>

      <section className="contents-container__1070">
        <table className="inner-table" width="660">
          <thead className="border-y">
            <tr>
              <th className="bg-white">
                <span>No</span>
              </th>
              <th className="bg-white">
                <span>이름</span>
              </th>
              <th className="bg-white">
                <span>연락처</span>
              </th>
              <th className="bg-white">
                <span>이메일</span>
              </th>
              <th className="bg-white"></th>
            </tr>
          </thead>
          <tbody>
            {providerContacts?.length && providerContacts?.length > 0 ? (
              providerContacts.map((contact: ProviderContactModel, idx: number) => (
                <tr key={contact.contactId}>
                  {contact.assignedWorkType === type && (
                    <>
                      <td width="80">
                        <div className="flex-center-center">
                          <span>{idx + 1}</span>
                        </div>
                      </td>
                      <td width="120">
                        <div className="flex-center-center">{contact.name}</div>
                      </td>
                      <td width="160">
                        <div className="flex-center-center">
                          <p>{formatPhoneNumber(String(contact.contact) || "-")}</p>
                        </div>
                      </td>
                      <td width="240">
                        <div className="flex-center-center minmax200">
                          <p>{contact.email}</p>
                        </div>
                      </td>
                    </>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} width="100">
                  <div className="flex-center-center">
                    <span>데이터가 없습니다</span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
    </article>
  );
};

export default ProviderContactTable;
