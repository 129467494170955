import React, { useEffect, useMemo } from "react";
import { SelectOption } from "src/pages/building/building-types";
import { BaseStateSelect } from "./BaseStateSelect";
import useNavigate from "src/hooks/usePartnerNavigate";

import { useLocation, useParams } from "react-router-dom";
import { useModal } from "src/recoil/modalState/hook";
import qs from "qs";
import { useQueryParams } from "src/hooks/useQueryParams";
import ModalList from "./ModalList";

type Props = {
  title: string;
  tabs?: Array<Tab>;
  activeTab?: Tab;
  clickTab?: Function;
  breadCrumbs?: Array<BreadCrumb>; // 제거 대상
  clickBreadCrumb?: Function; // 제거 대상
  className?: string;
  detailInfo?: Array<DetailInfo>;
  children?: React.ReactNode;
  selectValue?: string; // 셀렉트 선택값
  selectOptions?: Array<SelectOption>; // 셀렉트 옵션
  setSelectStateValue?: Function; // 셀렉트 변경함수
  colorType?: "product" | "workOrder" | "reservation" | "contract";
  selectDisabled?: boolean;
  selectOnFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

export type Tab = {
  value: string;
  label: string;
};

type BreadCrumb = {
  value: string;
  label: string;
};
type DetailInfo = {
  title: string;
  value: string | number;
};

export const ContentsTitle = ({
  tabs,
  title,
  activeTab,
  clickTab,
  className,
  detailInfo,
  selectValue,
  selectOptions,
  setSelectStateValue,
  children,
  colorType,
  selectDisabled,
  selectOnFocus,
}: Props) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
        decoder: (value) => value,
      }),
    [location],
  );

  const { setBaseModal } = useModal();

  const handleClickTab = (tab: { value: string; label: string }) => {
    if (!(queryParams.id || params.id) && tab.value !== "basicInfo") {
      // 등록 페이지에서 실행
      return setBaseModal({ isOpen: true, btnRightTitle: "확인", title: "기본정보가 저장되지 않았습니다" });
    }

    // query parameter 에 tab 추가
    const newQueryParamStr = qs.stringify({ ...queryParams, tab: tab.value, id: queryParams.id || params.id }, { allowDots: true });
    navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
  };

  return (
    <div className={`contents-title ${className ? className : ""}`}>
      <div className="flex-center">
        {children}
        <div className="contents-title__tab">
          {tabs?.map((tab: Tab, i: number) => {
            return (
              <div
                key={i}
                className={`${activeTab?.value === tab.value ? "active" : ""}`}
                onClick={() => (clickTab ? clickTab(tab) : handleClickTab(tab))}
                onMouseUp={(event) => {
                  if (event.button === 1) {
                    // 마우스의 휠 버튼 클릭 여부 확인
                    event.currentTarget.click();
                  }
                }}
              >
                <span>{tab.label}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex-center">
        {detailInfo?.map((info, i) => {
          return (
            <div key={i + title + info.value} className="contents-title__info">
              {info.value && (
                <span className="no-wrap">
                  {info.title}: {info.value}
                </span>
              )}
            </div>
          );
        })}
        {selectValue && selectOptions && setSelectStateValue && (
          <div className="minmax100">
            <BaseStateSelect
              value={selectValue}
              selectOptions={selectOptions}
              setStateValue={setSelectStateValue}
              isDisabled={selectDisabled}
              colorType={colorType}
            />
          </div>
        )}
      </div>
    </div>
  );
};
