import { Control, Controller, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { BaseSelect, BaseToggle } from "src/components";
import { BasicInfoFormData, Restroom, SelectOption } from "src/pages/building/building-types";

type Props = {
  control: Control<BasicInfoFormData>;
  getValues: UseFormGetValues<BasicInfoFormData>;
  setValue: UseFormSetValue<BasicInfoFormData>;
  floorOptions: Array<SelectOption>;
  restroomTypeOptions: Array<SelectOption>;
  restroomCountOptions: Array<SelectOption>;
};

// 화장실 컴포넌트
const Restrooms = ({ control, getValues, setValue, floorOptions, restroomTypeOptions, restroomCountOptions }: Props) => {
  const buildingToilet2List: Array<Restroom> = getValues("buildingToilet2List");

  // 추가 버튼 클릭
  const clickAddRestroom = () => {
    // 화장실 2 추가
    const maxSeq2 = Math.max.apply(
      Math,
      buildingToilet2List.map((o) => o.seq),
    );

    const newRestrooms2: Array<Restroom> = [
      ...buildingToilet2List,
      {
        seq: maxSeq2 + 1,
        floor: floorOptions[0].value,
        type: restroomTypeOptions[0].value,
        manCount: 1,
        womanCount: 1,
        count: 1,
      },
    ];
    setValue("buildingToilet2List", newRestrooms2, { shouldDirty: true, shouldValidate: true });
  };

  // 삭제 버튼 클릭
  const clickDeleteRestroom = (seq: number) => {
    // 화장실 2 삭제
    const newRestrooms2: Array<Restroom> = buildingToilet2List.filter((restroom) => restroom.seq !== seq);
    setValue("buildingToilet2List", newRestrooms2, { shouldDirty: true, shouldValidate: true });
  };

  // 층 선택
  const changeFloor = (selectedValue: string, seq: number) => {
    // 화장실 2 층 선택
    const currentRestrooms2: Array<Restroom> = [...buildingToilet2List];
    const newRestrooms2: Array<Restroom> = [];
    for (let i = 0; i < currentRestrooms2.length; i++) {
      const current = currentRestrooms2[i];
      if (current.seq === seq) {
        newRestrooms2.push({
          id: current?.id,
          seq: current.seq,
          floor: selectedValue,
          type: current.type,
          manCount: current.manCount,
          womanCount: current.womanCount,
          count: current.count,
          orderNums: current.orderNums,
        });
      } else {
        newRestrooms2.push(current);
      }
    }
    setValue("buildingToilet2List", newRestrooms2, { shouldDirty: true, shouldValidate: true });
  };

  // 구분 선택
  const changeRestroomType = (selectedValue: string, seq: number) => {
    // 화장실 2 구분 선택
    const currentRestrooms2: Array<Restroom> = [...buildingToilet2List];
    const newRestrooms2: Array<Restroom> = [];
    for (let i = 0; i < currentRestrooms2.length; i++) {
      const current = currentRestrooms2[i];
      if (current.seq === seq) {
        newRestrooms2.push({
          id: current?.id,
          seq: current.seq,
          floor: current.floor,
          type: selectedValue,
          manCount: 1,
          womanCount: 1,
          count: 1,
          orderNums: current.orderNums,
        });
      } else {
        newRestrooms2.push(current);
      }
    }
    // setValue("restrooms", newRestrooms, { shouldDirty: true, shouldValidate: true });
    setValue("buildingToilet2List", newRestrooms2, { shouldDirty: true, shouldValidate: true });
  };

  // 화장실 갯수 선택
  const changeRestroomCount = (selectedValue: string, seq: number, type?: string) => {
    // 화장실 2 갯수 선택
    const currentRestrooms2: Array<Restroom> = [...buildingToilet2List];
    const newRestrooms2: Array<Restroom> = [];
    for (let i = 0; i < currentRestrooms2.length; i++) {
      const current = currentRestrooms2[i];
      if (current.seq === seq) {
        let newObj = {
          id: current?.id,
          seq: current.seq,
          floor: current.floor,
          type: current.type,
          orderNums: current.orderNums,
        };
        if (type === "MAN") {
          newObj = Object.assign(newObj, {
            manCount: selectedValue,
            womanCount: current.womanCount,
            count: current.count,
          });
        } else if (type === "WOMAN") {
          newObj = Object.assign(newObj, {
            manCount: current.manCount,
            womanCount: selectedValue,
            count: current.count,
          });
        } else {
          newObj = Object.assign(newObj, {
            manCount: current.manCount,
            womanCount: current.womanCount,
            count: selectedValue,
          });
        }
        newRestrooms2.push(newObj);
      } else {
        newRestrooms2.push(current);
      }
    }
    setValue("buildingToilet2List", newRestrooms2, { shouldDirty: true, shouldValidate: true });
  };

  return (
    <Controller
      control={control}
      name="isUsedRestroom"
      render={({ field: { onChange, value, name } }) => (
        <>
          <div>
            <BaseToggle checked={value} onChange={onChange} name={name} />
          </div>
          {getValues("isUsedRestroom") && (
            <div>
              <table className="inner-table" width="600">
                <thead>
                  <tr>
                    <th>층</th>
                    <th>구분</th>
                    <th>남자화장실</th>
                    <th>여자화장실</th>
                    <th>버튼</th>
                  </tr>
                </thead>
                <tbody>
                  {buildingToilet2List.map((restroom, index) => (
                    <tr key={index}>
                      <td>
                        <BaseSelect
                          placeholder="층"
                          value={restroom.floor}
                          stateOptions={floorOptions}
                          setStateValue={(value: string) => changeFloor(value, restroom.seq)}
                        />
                      </td>
                      <td>
                        <BaseSelect
                          placeholder="구분"
                          value={restroom.type}
                          stateOptions={restroomTypeOptions}
                          setStateValue={(value: string) => changeRestroomType(value, restroom.seq)}
                        />
                      </td>
                      {restroom.type === "SEPARATION" ? (
                        <>
                          <td>
                            <BaseSelect
                              value={String(restroom.manCount || "")}
                              stateOptions={restroomCountOptions}
                              setStateValue={(value: string) => changeRestroomCount(value, restroom.seq, "MAN")}
                            />
                          </td>
                          <td>
                            <BaseSelect
                              value={String(restroom.womanCount || "")}
                              stateOptions={restroomCountOptions}
                              setStateValue={(value: string) => changeRestroomCount(value, restroom.seq, "WOMAN")}
                            />
                          </td>
                        </>
                      ) : (
                        <>
                          <td colSpan={2}>
                            <BaseSelect
                              value={String(restroom.count || "")}
                              stateOptions={restroomCountOptions}
                              setStateValue={(value: string) => changeRestroomCount(value, restroom.seq)}
                            />
                          </td>
                        </>
                      )}
                      <td>
                        <div className="flex-center-center">
                          {index === 0 ? (
                            <button type="button" className="base-add-btn" onClick={() => clickAddRestroom()}></button>
                          ) : (
                            <button type="button" className="base-erase-btn" onClick={() => clickDeleteRestroom(restroom.seq)}></button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    ></Controller>
  );
};

export default Restrooms;
