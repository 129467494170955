import { useEffect, useState } from "react";
import { Control, Controller, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { ProviderDetailModel, ProviderBasicInfoAddModel, ProviderMediaFile } from "src/api/provider/provider-types";
import { BaseInput, BaseRadio } from "src/components";
import { companyRegistrationHypen } from "src/utils";
import ContractUpload from "./ContractUpload";

const checkBizRegNoLink = "https://hometax.go.kr/websquare/websquare.html?w2xPath=/ui/pp/index_pp.xml&tmIdx=43&tm2lIdx=4306000000&tm3lIdx=4306080000";

type Props = {
  control?: Control<ProviderBasicInfoAddModel>;
  register?: UseFormRegister<ProviderBasicInfoAddModel>;
  errors?: any;
  providerDetail?: ProviderDetailModel;
  providerId?: string;
  setValue: UseFormSetValue<ProviderBasicInfoAddModel>;
};

const ProviderBasicForm = ({ control, providerDetail, providerId, errors, setValue }: Props) => {
  const [document, setDocument] = useState<ProviderMediaFile[]>([]);
  useEffect(() => {
    if (providerDetail?.contractMediaList && providerDetail.contractMediaList?.length > 0) {
      setDocument(providerDetail.contractMediaList);
    }
  }, [providerDetail]);

  return (
    <article>
      {/* 유형 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="required">유형</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax450">
            <span>
              {Boolean(Number(providerId)) && providerDetail?.provider?.supplyType === "RENTAL" && "프로바이더"}
              {Boolean(Number(providerId)) && providerDetail?.provider?.supplyType === "MNT" && "관리처"}
            </span>
            {!Boolean(Number(providerId)) && (
              <Controller
                control={control}
                name={`provider.supplyType`}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                  return (
                    <div className="flex-center ">
                      {}
                      <BaseRadio
                        id={"provider"}
                        name={name}
                        label={"프로바이더"}
                        checked={value === "RENTAL"}
                        className="mr20"
                        onChange={(checked: boolean) => {
                          onChange(checked);
                          if (checked) {
                            setValue("provider.supplyType", "RENTAL", { shouldDirty: true });
                          }
                        }}
                        readOnly={value !== "" && true}
                      />
                      <BaseRadio
                        id={"manamgement"}
                        name={name}
                        label={"관리처"}
                        checked={value === "MNT"}
                        onChange={(checked: boolean) => {
                          onChange(checked);
                          if (checked) {
                            setValue("provider.supplyType", "MNT", { shouldDirty: true });
                          }
                        }}
                        readOnly={value !== ""}
                      />
                    </div>
                  );
                }}
              ></Controller>
            )}
          </div>
          <p className="validation-text">{errors.provider?.supplyType?.message}</p>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="required">법인명/상호</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax450">
            <Controller
              control={control}
              name={`provider.providerName`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseInput
                    onChange={onChange}
                    value={value}
                    name={name}
                    errorText={error && error.message}
                    placeholder="사업자 등록증에 기재된 사업자명을 입력해주세요"
                  />
                );
              }}
            ></Controller>
          </div>
        </div>
      </section>
      <section className="contents-container__grid mb30">
        <div className="contents-container__grid-index">
          <p className="required">사업자 등록번호</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax450">
            <Controller
              control={control}
              name={`provider.businessRegistrationNumber`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseInput
                    onChange={(text: string) => onChange(text)}
                    value={companyRegistrationHypen(String(value))}
                    maxLength={13}
                    name={name}
                    errorText={error && error.message}
                    placeholder="법인등록번호 13자리 또는 사업자 등록번호 10자리를 입력해주세요"
                  />
                );
              }}
            ></Controller>
          </div>
          <div className="flex-center mt10 text-red900 font14">
            사업자 등록번호를 입력하기 전에
            <a href={checkBizRegNoLink} target="_blank" className="mx6 text-underline" rel="noreferrer">
              휴/폐업 조회
            </a>
            에서 상태를 확인해주세요.
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="required">대표자/성명</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax450">
            <Controller
              control={control}
              name={`provider.representative`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseInput
                    onChange={onChange}
                    value={value}
                    name={name}
                    errorText={error && error.message}
                    placeholder="사업자 등록증에 기재된 대표자명을 입력해주세요"
                  />
                );
              }}
            ></Controller>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="required">사업장 주소지</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax450">
            <Controller
              control={control}
              name={`provider.address`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseInput
                    onChange={onChange}
                    value={value}
                    name={name}
                    errorText={error && error.message}
                    placeholder="사업자 등록증에 기재된 사업장 주소를 입력해주세요"
                  />
                );
              }}
            ></Controller>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">업태</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax450">
            <Controller
              control={control}
              name={`provider.bizType`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseInput
                    onChange={onChange}
                    value={value}
                    name={name}
                    errorText={error && error.message}
                    placeholder="사업자 등록증에 기재된 업태를 입력해주세요"
                  />
                );
              }}
            ></Controller>
          </div>
        </div>
      </section>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">종목</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax450">
            <Controller
              control={control}
              name={`provider.bizClass`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseInput
                    onChange={onChange}
                    value={value}
                    name={name}
                    errorText={error && error.message}
                    placeholder="사업자 등록증에 기재된 종목을 입력해주세요"
                  />
                );
              }}
            ></Controller>
          </div>
        </div>
      </section>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="required">{`사업자등록증 사본(${document.filter((item) => item.isDeleted !== true).length}/1)`}</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-center-start flex-row">
            <div className="flex-center mr16">
              <Controller
                control={control}
                name="contractMediaList"
                render={({ field: { onChange, name, value }, fieldState: { error } }) => (
                  <>
                    <ContractUpload
                      setPrivateFiles={(file: ProviderMediaFile[]) => {
                        setDocument(file);
                        onChange(file);
                      }}
                      privateFiles={document}
                      errorText={error && error.message}
                      limit={1}
                      maxSize={10}
                    />
                  </>
                )}
              ></Controller>
            </div>
          </div>
        </div>
      </section>
    </article>
  );
};

export default ProviderBasicForm;
