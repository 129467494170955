import moment from "moment";
import React from "react";
import { ProviderPayletterModel } from "src/api/provider/provider-types";
import { BaseButton } from "src/components";

type Props = {
  payletter?: ProviderPayletterModel;
  payletterApiCheck?: (payletter: ProviderPayletterModel) => Promise<void>;
  type?: "form" | "detail";
};

const AutoPayletterStatus = ({ payletter, payletterApiCheck, type }: Props) => {
  return (
    <>
      <div className="index-tab">
        <span>자동결제 API 연동 상태</span>
      </div>
      <div className="border-gray py16 pr16 px20">
        <section className="flex-center mb10">
          {type === "detail" && payletter && (
            <div className="flex-center">
              <BaseButton title="검증" onClick={() => payletterApiCheck?.(payletter)} />
              <p className="font12 ml10">{payletter?.verifiedDate ? moment(payletter?.verifiedDate).format("YYYY.MM.DD HH:mm:ss") : ""}</p>
            </div>
          )}
        </section>
        <section className="flex-center">
          <div className="minmax200 d-flex">
            <span className="font13 text-gray600">Payment API Key: </span>
            <span className=" font13 ml10">{payletter?.isVerifiedPaymentKey ? "연동 성공" : "연동 실패"}</span>
          </div>

          <div className="minmax200">
            <span className="font13 text-gray600">Search API Key: </span>
            <span className=" font13 ml10">{payletter?.isVerifiedSearchKey ? "연동 성공" : "연동 실패"}</span>
          </div>
        </section>
      </div>
    </>
  );
};

export default AutoPayletterStatus;
