import qs from "qs";
import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ContentsTitle } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import { useValidRoutes } from "src/hooks/useValidRoutes";
import BuildingIotDevice from "../forms/iotDevice/BuildingIotDevice";
import BasicInfoDetail from "./basicInfoDetail/BasicInfoDetail";
import BuildingImagesDetail from "./buildingImagesDetail/BuildingImagesDetail";
import CommonFacility from "./facilityDetail/CommonFacility";
import MgmtOfficeDetail from "./mgmtOfficeDetail/MgmtOfficeDetail";
import PropertyDetail from "./propertyDetail/PropertyDetail";
import SeatingChartDetail from "./seatingChartDetail/SeatingChartDetail";
import VocService from "./vocService/VocService";
import HistoryDetail from "src/components/histroy/HistoryDetail";

const tabs = [
  { value: "basicInfo", label: "기본정보" },
  { value: "property", label: "프로퍼티" },
  { value: "facility", label: "공용공간" },
  { value: "seatingChart", label: "좌석 배치도" },
  { value: "image", label: "이미지" }, // TODO: MVP 이후
  { value: "mgmtOffice", label: "관리처" },
  { value: "csItem", label: "VoC 항목" },
  { value: "iotDevice", label: "IoT 기기" },
  ...(process.env.REACT_APP_ENVIRONMENT === "dev" ? [{ value: "history", label: "변경내역" }] : []),
];

/* 
  건물 > 상세
 */
const BuildingDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id: buildingId } = useParams();

  const { isCsActive, goToPageNotFound } = useValidRoutes();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
        decoder: (value) => value,
      }),
    [location],
  );

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }

      // 공용공간 tab 이 아닐 때, facilityType 제거
      if (newQueryParams?.tab !== "facility" && newQueryParams?.facilityType) {
        delete newQueryParams.facilityType;
      }
      if (newQueryParams?.tabType) {
        delete newQueryParams?.tabType;
        delete newQueryParams?.tabId;
      }

      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });

      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  useEffect(() => {
    goToPageNotFound();
  }, [goToPageNotFound]);

  return (
    <div className="building-page">
      <ContentsTitle
        title="건물"
        tabs={tabs.filter((item) => (isCsActive ? item : item.value !== "csItem"))}
        activeTab={activeTab}
        clickTab={clickTab}
        detailInfo={[{ title: "id", value: buildingId || "" }]}
      />
      {/* 탭 */}
      {/* 기본정보 */}
      {activeTab?.value === "basicInfo" && <BasicInfoDetail />}
      {/* 프로퍼티 */}
      {activeTab?.value === "property" && <PropertyDetail />}
      {/* 공용공간 */}
      {activeTab?.value === "facility" && <CommonFacility />}
      {/* 좌석 배치도 */}
      {activeTab?.value === "seatingChart" && <SeatingChartDetail />}
      {/* 이미지 */}
      {activeTab?.value === "image" && <BuildingImagesDetail />}
      {/* 관리처 */}
      {activeTab?.value === "mgmtOffice" && <MgmtOfficeDetail buildingId={String(buildingId || "")} />}
      {/* voc */}
      {activeTab?.value === "csItem" && <VocService serviceId={buildingId || ""} />}
      {/* IoT */}
      {activeTab?.value === "iotDevice" && <BuildingIotDevice />}
      {/* 변경내역 */}
      {activeTab?.value === "history" && buildingId && <HistoryDetail identifier={{ buildingId: String(buildingId) }} />}
    </div>
  );
};
export default BuildingDetail;
