import { Cell } from "react-table";
import { ProductModel } from "src/api/product/product-types";
import { BaseTooltip } from "src/components";

import { formatToKRW } from "@toss/utils";
import DetailLink from "src/components/DetailLink";
import ProductStatusLabel from "src/components/ProductStatusLabel";
import { PagePath } from "../details";
import { ProductStatusT, ProductTypeLabels } from "../product-types";
import IsUsedCell from "./IsUsedCell";
import ProductDateCell from "./ProductDateCell";
import ProductTypeChangeLabel from "src/components/ProductTypeChangeLabel";
import Link from "src/components/PartnerLink";

/* 
  공간상품 테이블 columns  
  공간상품명 / 건물명 / 주소 = 두줄 팝업
  나머지 한줄 팝업
*/
const ProductColumns = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
    Cell: (props: Cell<ProductModel>) => {
      const detailPath = `${PagePath.product.detail.replace(":id", `${props.row.original.id}`)}`;

      return (
        <div>
          <DetailLink to={detailPath} className="text-underline">
            <BaseTooltip contents={props?.value || ""} />
          </DetailLink>
        </div>
      );
    },
    sticky: "left",
  },
  {
    Header: "이용여부",
    accessor: "isUsed",
    width: 80,

    Cell: (props: Cell<ProductModel>) => <IsUsedCell {...props} />,
    sticky: "left",
  },

  {
    Header: "상품명",
    accessor: "productName",
    width: 240,
    Cell: (props: Cell<ProductModel>) => {
      const detailPath = `${PagePath.product.detail.replace(":id", `${props.row.original.id}`)}`;

      return (
        <div className="flex-center-start w-100">
          <Link to={detailPath} className="text-underline w-full">
            <BaseTooltip contents={props?.value || ""} />
          </Link>
        </div>
      );
    },
    sticky: "left",
  },

  {
    Header: "상품유형",
    accessor: "productType",
    Cell: (props: Cell<ProductModel>) => <ProductTypeChangeLabel productType={props.row.original.productType} />,
    width: 120,
  },

  {
    Header: "보증금",
    accessor: "deposit",
    Cell: (props: Cell<ProductModel>) => {
      if (!props?.row?.original?.deposit) {
        return <div className="flex-center-end w-100">{"-"}</div>;
      }
      return (
        <div className="flex-center-end w-100">
          <BaseTooltip contents={formatToKRW(Number(props?.row?.original?.deposit))} isSingleLine />
        </div>
      );
    },

    width: 120,
  },
  {
    Header: "판매가",
    accessor: "rentalCost",
    Cell: (props: Cell<ProductModel>) => {
      const price = props?.row.original?.productionPrice;
      const productRentType = props?.row.original?.costType;

      if (!price || !productRentType || price === -1) {
        return <div className="flex-center-end w-100">{"-"}</div>;
      }

      return (
        <div className="flex-center-end w-100">
          <BaseTooltip contents={`${formatToKRW(Number(price))}${productRentType === "COST_BILLING_MONTHLY" ? " / 1개월" : ""}`} isSingleLine />
        </div>
      );
    },

    width: 120,
  },

  {
    Header: "공개여부",
    accessor: "status",
    width: 80,
    Cell: (props: Cell<ProductModel>) =>
      props.row.original.status ? <ProductStatusLabel status={props.row.original.status as ProductStatusT} /> : "-",
  },

  {
    Header: "건물명",
    accessor: "buildingName",
    width: 120,

    Cell: (props: Cell<ProductModel>) => {
      return (
        <div className="flex-center-start w-100">
          <BaseTooltip contents={props?.value ?? "-"} />
        </div>
      );
    },
  },

  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    Cell: (props: Cell<ProductModel>) => <ProductDateCell {...props} />,
    width: 120,
  },

  {
    Header: "최종 수정자",
    accessor: "modifiedBy",
    Cell: (props: Cell<ProductModel>) => <BaseTooltip contents={props?.value} isSingleLine />,
    width: 120,
  },
];

export default ProductColumns;
