import qs from "qs";
import { useMemo } from "react";
import { Link as ReactRouterLink, LinkProps, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { globalPartnerState } from "src/recoil/partners/atom";

function DetailLink(props: LinkProps) {
  const partner = useRecoilValue(globalPartnerState);
  const location = useLocation();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    allowDots: true,
    decoder: (value) => value,
  });
  const encodeQueryParams = useMemo(() => encodeURIComponent(qs.stringify(queryParams, { allowDots: true })), [queryParams]);

  if (typeof props.to === "string") {
    const _to = props.to.replace(":partnerId", String(partner?.id));

    return <ReactRouterLink {...props} to={encodeQueryParams ? _to + `${props.to.includes("?") ? "&" : "?"}list=${encodeQueryParams}` : _to} />;
  }

  return <ReactRouterLink {...props} />;
}
export default DetailLink;
