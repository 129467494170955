import _ from "lodash";
import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { getContractApply } from "src/api/contract/contract-api";
import { ContractManageList, searchDateTypeOption, searchStatusOption, searchTypeOption } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { Modal, Order, PageMeta } from "src/api/public-types";
import { BaseButton, BaseInput, BaseInputWithSearch, BaseRadio, BaseSelect, BaseTable, BaseTooltip } from "src/components";
import BaseMultiSelect from "src/components/BaseMultiSelect";
import RangeDatepickerWithSelect from "src/components/RangeDatepickerWithSelect";
import useNavigate from "src/hooks/usePartnerNavigate";
import SearchBuildingPopup from "src/pages/commonPopup/SearchBuildingPopup";
import SearchOrgPopup from "src/pages/commonPopup/SearchOrgPopup";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { ViewYmdFormat, YmdFormat, calculateDate, downloadExcel, qsParse } from "src/utils/common-util";
import { columns } from "./columns/Columns";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { ProductTypeLabels, ProductTypes } from "src/pages/product/product-types";
import ExcelDownloadButton from "src/pages/user/list/ExcelDownloadButton";
import { checkContractStatus } from "../contract-util";

const ProductType = [
  { value: "", label: "전체" },
  { value: ProductTypes["FULL_COURT"], label: ProductTypeLabels["FULL_COURT"] },
  { value: ProductTypes["TIME_COURT"], label: ProductTypeLabels["TIME_COURT"] },
  { value: ProductTypes["MAINTENANCE_FEE"], label: ProductTypeLabels["MAINTENANCE_FEE"] },
  { value: ProductTypes["NON_RESIDENT_OFFICE"], label: ProductTypeLabels["NON_RESIDENT_OFFICE"] },
  { value: ProductTypes["DIGITAL_PRODUCT"], label: ProductTypeLabels["DIGITAL_PRODUCT"] },
];
const ContractList = () => {
  const { isAuthority } = usePartnerAuthority();
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = dateRange;
  const { openErrorModal } = useErrorModal();
  const navigate = useNavigate();
  const location = useLocation();
  const [building, setBuilding] = useState<any>({ buildingName: "" });
  const [isBuildingOpen, setIsBuildingOpen] = useState(false);
  const [org, setOrg] = useState<any>({ name: "" });
  const [isOrgOpen, setIsOrgOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [partners, setPartners] = useState<any>([]);
  const orgColumns: any = [
    {
      Header: "선택",
      accessor: "isSelected",
      width: 80,
      Cell: ({ row, setSelectedOrg }: any) => {
        const changeTrigger = useCallback(() => {
          setSelectedOrg(row.original);
        }, [row.original, setSelectedOrg]);

        return <BaseRadio id={`selector${row.original.id}`} name={"isSelected"} onChange={changeTrigger} value={row.original.isSeleted} />;
      },
    },
    {
      Header: "id",
      accessor: "id",
      width: 120,
    },
    {
      Header: "법인명/상호",
      accessor: "name",
      width: 740,
      Cell: ({ value }: any) => {
        return <div className="w-100 text-left">{value ? <BaseTooltip contents={value} /> : "-"}</div>;
      },
    },
  ];

  // console.log(location.search, "location.search");
  const queryParams: any = useMemo(() => {
    const _queryParams: any = qsParse(location.search);

    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }

    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "contractManageId", direction: "DESC" }],
      };
    }
    if (_queryParams?.id) {
      delete _queryParams?.id;
    }
    if (!_queryParams?.searchType) {
      _queryParams.searchType = "";
    }
    if (!_queryParams?.searchValue) {
      _queryParams.searchValue = "";
    }

    const dump = _.cloneDeep(dateRange);
    if (_queryParams?.searchStartTime !== undefined && _queryParams?.searchStartTime !== "") {
      console.log("_queryParams?.searchStartTime", _queryParams?.searchStartTime);
      _queryParams.searchStartTime = moment(_queryParams?.searchStartTime).toDate();

      dump[0] = moment(_queryParams?.searchStartTime).toDate();
    } else {
      dump[0] = null;
    }
    if (_queryParams?.searchEndTime !== undefined && _queryParams?.searchEndTime !== "") {
      console.log("_queryParams?.searchEndTime", moment(_queryParams?.searchEndTime).format(ViewYmdFormat.YYYY_MM_DD));
      _queryParams.searchEndTime = moment(moment(_queryParams?.searchEndTime).format(ViewYmdFormat.YYYY_MM_DD)).toDate();

      dump[1] = moment(_queryParams?.searchEndTime).toDate();
    } else {
      dump[1] = null;
    }
    console.log("setQueryParams", dump);
    setDateRange(dump);

    if (!_queryParams?.searchValue) {
      _queryParams.searchValue = "";
    }

    if (_queryParams.buildingName === undefined || _queryParams.buildingName === "") {
      _queryParams.buildingName = "";
      delete _queryParams.buildingId;
    }
    if (_queryParams.orgName === undefined || _queryParams.orgName === "") {
      _queryParams.orgName = "";
      delete _queryParams.mbOrganizationId;
    }

    return _queryParams;
  }, [location]);
  const [params, setParams] = useState<any>({ ...queryParams });
  const [data, setData] = useState<ContractManageList[] | undefined>(undefined);
  const [pagination, setPagination] = useState<PageMeta>();

  //호출부
  const { executeAsync } = useApiOperation(getContractApply);

  // 쿼리파라미터 정보와 함께 네비게이션
  const navigateWithQueryParams = useCallback(
    (passParams?: any) => {
      const newQueryParams = { ...params, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true, encode: true });
      console.log("navigateWithQueryParams", newQueryParams);
      setParams(newQueryParams);
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [params, navigate, location.pathname],
  );

  const settingBuilding = useCallback(
    (bd: any) => {
      setIsBuildingOpen(false);
      setBuilding(bd);

      navigateWithQueryParams({ ...params, ...{ buildingId: bd.id, buildingName: bd.buildingName } });
    },
    [params],
  );

  const settingOrg = useCallback(
    (org: any) => {
      setIsOrgOpen(false);
      setOrg(org);

      navigateWithQueryParams({ ...params, ...{ mbOrganizationId: org.id, orgName: org.name } });
    },
    [params],
  );

  const callList = useCallback(
    async (excelCall?: boolean) => {
      const param = _.cloneDeep(queryParams);
      if (param.buildingName === undefined || param.buildingName === "") {
        delete param.buildingId;
      }
      if (param.orgName === undefined || param.orgName === "") {
        delete param.mbOrganizationId;
      }
      if (dateRange[0]) {
        let startTime = moment(dateRange[0]).format(YmdFormat.YYYY_MM_DD) + "T00:00:00.000+09:00";
        param.searchStartTime = startTime;
        setParams({ ...param, ...{ searchStartTime: startTime } });
      }
      if (dateRange[1]) {
        let endTime = moment(dateRange[1]).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00";

        console.log("endTime", endTime);
        param.searchEndTime = endTime;
        setParams({ ...param, ...{ searchEndTime: endTime } });
      }
      console.log("callList", param);
      param.searchValue = param.searchValue ? decodeURIComponent(String(param.searchValue)) : "";
      setParams({ ...queryParams, ...param });
      let arrData: any = [];

      if (excelCall) {
        const newCall = _.cloneDeep(params);
        newCall.page = 0;
        newCall.size = 1;
        const check: any = await executeAsync(newCall);
        const { meta } = check.data;
        const fixedCallSize = 100;
        if (meta?.pageMeta?.totalElements > 0) {
          let callSize = Math.floor(meta?.pageMeta?.totalElements / fixedCallSize);
          const dvSize = meta?.pageMeta?.totalElements % fixedCallSize;
          // console.log("dv", callSize, dvSize);
          if (dvSize > 0) {
            callSize = callSize + 1;
          }
          // console.log("callSize", callSize, dvSize);

          let callList: any = [];
          for (let i = 0; i <= callSize; i++) {
            newCall.page = i;
            newCall.size = fixedCallSize;
            callList.push(
              new Promise((resolve, reject) => {
                resolve(executeAsync(newCall));
              }),
            );
          }
          const result2: any = await Promise.all(callList);
          result2.forEach((response: any) => {
            arrData = [...arrData, ...response.data.data.content];
          });
        }
        if (arrData.length === 0) {
          setAlertModal({ isOpen: true, message: "조회된 데이터가 없습니다." });
          return;
        }

        return arrData;
      } else {
        let response: any = await executeAsync(param);

        if (response && response.status >= 200 && response.status <= 299) {
          setData(response.data.data.content);
          setPagination(response.data.meta.pageMeta);
        }
      }
    },
    [dateRange, params, executeAsync, queryParams],
  );

  const handleOnDateRangeChange = (dateRange: [Date | null, Date | null]) => {
    setDateRange(dateRange);
    let startTime = "";
    let endTime = "";
    if (dateRange[0]) {
      startTime = moment(moment(dateRange[0]).format(ViewYmdFormat.YYYY_MM_DD) + "T00:00:00.000Z", "YYYY.MM.DDTHH:mm:ss.SSSZ").format(
        "YYYY-MM-DDTHH:mm:ss.SSSZ",
      );

      setParams({ ...params, ...{ searchStartTime: startTime } });
    }
    if (dateRange[1]) {
      endTime = moment(moment(dateRange[1]).format(ViewYmdFormat.YYYY_MM_DD) + "T23:59:59.999Z", "YYYY.MM.DDTHH:mm:ss.SSSZ").format(
        "YYYY-MM-DDTHH:mm:ss.SSSZ",
      );

      navigateWithQueryParams({ ...params, ...{ searchStartTime: startTime, searchEndTime: endTime, page: 0 } });
    }
  };

  const returnContractSteps = useMemo(() => {
    let rtn: any = [];
    // console.log("params.contractStep", params.contractStep);
    if (params.contractStep !== undefined && params.contractStep !== "") {
      rtn = params.contractStep.split(",");
    }

    return rtn;
  }, [params.contractStep]);

  useEffect(() => {
    callList(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleClickExcelDownloadButton = async () => {
    const result: any = await callList(true);
    console.log("excel", result);
    const data = result;

    if (!data || data.length === 0) {
      setAlertModal({ isOpen: true, message: "조회된 데이터가 없습니다." });
      return;
    }
    // const mappedData: any = [];
    const mappedData = data.map((item: any) => ({
      id: item.contractId ?? "-",
      contractApplyNumber: item.contractApplyNumber ? item.contractApplyNumber : "-",
      contractStep: item.contractStep ? checkContractStatus(item.contractStep) : "-",
      mbOrganizationName: item.mbOrganizationName ? item.mbOrganizationName : "-",
      applicantName: item.applicantName ? item.applicantName : "-", // 방문자 번호
      contractName: item.contractName ? item.contractName : "-",
      spaceProductName: item.spaceProductName ? item.spaceProductName : "-", // 시작
      spaceProductType: item.spaceProductType ? ProductTypeLabels[item.spaceProductType as keyof typeof ProductTypes] : "-", // 종료
      spaceProductId: item.spaceProductId ? item.spaceProductId : "-", // 종료
      useNums: item.memberTotCount ? item.memberTotCount + "명 / " + item.memberMaxNums + "명" : "-", // 종료
      contractStartTime: moment(item.contractStartTime).format("YYYY-MM-DD HH:mm") ?? "-",
      contractEndTime: moment(item.contractEndTime).format("YYYY-MM-DD HH:mm") ?? "-",
      contractTerm: calculateDate(item.contractStartTime!, item.contractEndTime!, item.spaceProductType!) ?? "-",
      createdDate: moment(item.createdDate).format("YYYY-MM-DD HH:mm") ?? "-",
      modifiedDate: moment(item.modifiedDate).format("YYYY-MM-DD HH:mm") ?? "-",
    }));

    try {
      const fileName = `ctrl.room-신청/계약-전체_${moment().format("YYYYMMDDHHmmss")}`;

      console.log("mappedData :>> ", mappedData);

      await downloadExcel({
        data: mappedData,
        fileName,
        header: columns.map((column) => column.Header),
      });

      setAlertModal({ isOpen: true, message: "엑셀 다운로드가 완료되었습니다." });
    } catch (error) {
      setAlertModal({ isOpen: true, message: "엑셀 다운로드에 실패하였습니다." });
    }
  };

  // console.log("pagination", pagination);
  return (
    <div className="page-product-list">
      <div className="contents-container__table">
        <div className="contents-container__search-wrap">
          <div className="left-area">
            <section>
              <div className="left-area__index">
                <span>지정검색</span>
              </div>
              <div className="left-area__contents">
                <div className="minmax140">
                  <BaseInput
                    type="text"
                    value={decodeURIComponent(params?.buildingName) || ""}
                    placeholder="건물 선택"
                    readonly={true}
                    onClick={(e: any) => {
                      e.preventDefault();
                      setIsBuildingOpen(true);
                    }}
                    onClearClick={() => {
                      let cl = _.cloneDeep(params);
                      cl.buildingName = "";
                      delete cl.buildingId;
                      navigateWithQueryParams({ ...params, ...cl });
                    }}
                  />
                  <SearchBuildingPopup
                    isOpen={isBuildingOpen}
                    partnerId={params.partnerId}
                    buildingName={params?.buildingName}
                    onClick={settingBuilding}
                    onClose={() => setIsBuildingOpen(false)}
                  />
                </div>
                <div className="minmax140">
                  <BaseInput
                    type="text"
                    value={decodeURIComponent(params?.orgName || "")}
                    placeholder="사업자 선택"
                    readonly={true}
                    onClick={(e: any) => {
                      e.preventDefault();
                      setIsOrgOpen(true);
                    }}
                    onClearClick={() => {
                      let cl = _.cloneDeep(params);
                      cl.orgName = "";
                      delete cl.mbOrganizationId;
                      navigateWithQueryParams({ ...params, ...cl });
                    }}
                  />
                  <SearchOrgPopup
                    isOpen={isOrgOpen}
                    partnerId={params.partnerId}
                    name={params?.orgName}
                    columns={orgColumns}
                    placeholder={"법인명/상호를 입력해 주세요"}
                    onClick={settingOrg}
                    onClose={() => setIsOrgOpen(false)}
                  />
                </div>
              </div>
            </section>
            <section>
              <div className="left-area__index">
                <span>조건검색</span>
              </div>
              <div className="left-area__contents">
                <div className="">
                  {/* <RangeDatepicker dateRange={dateRange} onChange={handleOnDateRangeChange} /> */}
                  <RangeDatepickerWithSelect
                    stateOptions={searchDateTypeOption}
                    selectValue={params.searchTimeType || ""}
                    setStateValue={(searchTimeType: string) => {
                      if (params.searchStartTime && params.searchEndTime) {
                        navigateWithQueryParams({ ...params, searchTimeType });
                      } else {
                        console.log("params", params);
                        setParams({ ...params, searchTimeType });
                      }
                    }}
                    selectWidth={120}
                    dateRange={dateRange}
                    onChange={handleOnDateRangeChange}
                  />
                </div>
                <div className="minmax140 ">
                  <BaseMultiSelect
                    placeholder="계약상태"
                    stateOptions={searchStatusOption}
                    value={returnContractSteps || []}
                    setStateValue={(options: Array<{ value: string; label: string }>) => {
                      let status = "";
                      options.map((obj: { value: string; label: string }) => {
                        if (status === "") {
                          status = obj.value;
                        } else {
                          status += "," + obj.value;
                        }
                      });
                      let dump = _.cloneDeep(params);
                      dump.page = 0;
                      dump.contractStep = status;
                      navigateWithQueryParams(dump);
                    }}
                  />
                  {/* <BaseSelect
                    value={params.contractStep}
                    placeholder="상태"
                    stateOptions={searchStatusOption}
                    setStateValue={(contractStep: string) => {
                      navigateWithQueryParams({ ...params, ...{ contractStep, page: 0 } });
                    }}
                  /> */}
                </div>
                <div>
                  <BaseSelect
                    value={params.spaceProductType}
                    stateOptions={ProductType}
                    setStateValue={(value: string) => {
                      navigateWithQueryParams({ ...params, ...{ spaceProductType: value, page: 0 } });
                    }}
                    placeholder="상품 유형"
                  />
                </div>
                <div>
                  <BaseInputWithSearch
                    type="text"
                    selectValue={params.searchType}
                    inputValue={decodeURIComponent(String(params?.searchValue) || "")}
                    stateOptions={searchTypeOption}
                    setStateValue={(searchType: string) => {
                      setParams({ ...params, searchType });
                    }}
                    onChange={(keyword: string) => setParams({ ...params, ...{ searchValue: keyword } })}
                    onKeyUp={() => navigateWithQueryParams({ ...params, ...{ page: 0 } })}
                    onSearchClick={() => navigateWithQueryParams({ ...params, ...{ page: 0 } })}
                    onClearClick={() => navigateWithQueryParams({ ...params, ...{ searchValue: "" } })}
                  />
                </div>
              </div>
            </section>
          </div>
          <div className="right-area">{isAuthority("w") && <BaseButton title="+ 신청/계약 생성" onClick={() => navigate("form")} />}</div>
        </div>

        {/* 샘플 */}
        {data !== undefined && (
          <BaseTable
            data={data}
            columns={columns}
            pageIndex={Number(params?.page) || 0}
            currentSize={Number(queryParams.size) || 20}
            sizeOption={(sizeValue) => {
              navigateWithQueryParams({ ...params, size: sizeValue, page: 0 });
            }}
            totalPages={Number(pagination?.totalPages) || 0}
            totalElements={Number(pagination?.totalElements) || 0}
            goPage={(page: number) => {
              // setParams({ ...params, ...{ page } });
              navigateWithQueryParams({ ...params, ...{ page, size: queryParams.size } });
            }}
            disabledSortHeaders={["mbOrganizationName", "useNums"]}
            orders={params?.sort?.orders}
            setOrders={(orders?: Array<Order>) => {
              if (orders) {
                // setParams({ ...params, ...{ sort: { orders } } });
                navigateWithQueryParams({ ...params, ...{ sort: { orders } } });
              }
            }}
            children={<ExcelDownloadButton onClick={handleClickExcelDownloadButton}>엑셀받기</ExcelDownloadButton>}
          />
        )}
      </div>
    </div>
  );
};

export default ContractList;
