import React, { useState } from "react";
import { ProviderPayletterModel } from "src/api/provider/provider-types";
import { BaseButton, BaseModal, BaseTooltip } from "src/components";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import AutoPayletterStatus from "./AutoPayletterStatus";
import { Modal } from "src/api/public-types";
type Props = {
  payletter?: ProviderPayletterModel;
  basicPayletter?: ProviderPayletterModel;
  isEdit: () => void;
  payletterApiCheck: (payletter: ProviderPayletterModel) => Promise<void>;
};

const AutoPayletterDetail = ({ payletter, basicPayletter, isEdit, payletterApiCheck }: Props) => {
  // 경고창 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });
  return (
    <article className="contents-container__wrap-article">
      <div className="contents-container__sub-title flex-column">
        <div className="flex-center-between w-100">
          <div className="flex-center">
            <BaseSectionTitle title={"신용카드 자동결제"} className="pb0" />
            <BaseTooltip touchIcon="QUESTION" className="">
              <ul className="pl20">
                <li className="mb5">자동결제는 정해진 일정에 맞춰 자동으로 정기 결제가 진행되는 방식입니다.</li>
                <li>
                  신용카드 자동결제를 제공하려면
                  <a href="https://www.payletter.com/ko/service/domestic" target={"_blank"} className="text-blue px5" rel="noreferrer">
                    페이레터
                  </a>
                  에서 자동결제id와 API Key를 발급받은 후 연동을 완료해주세요.
                </li>
              </ul>
            </BaseTooltip>
          </div>
          <BaseButton
            title="수정"
            className="color-white"
            onClick={() => {
              if (!basicPayletter) {
                setAlertModal({
                  isOpen: true,
                  message: "신용카드 일반결제 정보를\n 먼저 등록하세요.",
                });
                return;
              }
              isEdit();
            }}
          />
        </div>
      </div>
      {payletter ? (
        <section className="contents-container__1200">
          <div className="mb10">
            <div className="index-tab">
              <span>일반결제 API 연동 설정</span>
            </div>
            <div className="border-gray py16 pr16 px20">
              <section className="flex-center mb10">
                <div className="minmax123">
                  <span className=" font13 text-gray600">가맹점 id</span>
                </div>
                <p className="font13">{payletter?.clientId}</p>
              </section>
              <section className="flex-center mb10">
                <div className="minmax123">
                  <span className=" font13 text-gray600">결제 API Key</span>
                </div>
                <p className="font13">{payletter?.paymentKey?.replace(/./g, "*")}</p>
              </section>
              <section className="flex-center">
                <div className="minmax123">
                  <span className=" font13 text-gray600">조회 API Key</span>
                </div>
                <p className="font13">{payletter?.searchKey?.replace(/./g, "*")}</p>
              </section>
            </div>
            <div className="mt10 mb30">
              <AutoPayletterStatus payletter={payletter} type="detail" payletterApiCheck={payletterApiCheck} />
            </div>
          </div>
        </section>
      ) : (
        <p className="font14">입력된 정보가 없습니다</p>
      )}
      <BaseModal
        isOpen={alertModal.isOpen}
        btnRightTitle="확인"
        title={!alertModal.payload ? alertModal.message : ""}
        onClick={() => {
          setAlertModal({ isOpen: false, payload: "" });
        }}
      ></BaseModal>
    </article>
  );
};

export default AutoPayletterDetail;
