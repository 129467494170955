import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useApiOperation } from "src/api/hooks";
import { editProviderBasicInfoAsync, postProviderBasicInfoAsync, providerDetailAsync } from "src/api/provider/provider-api";
import { ProviderBasicInfoAddModel, ProviderDetailModel } from "src/api/provider/provider-types";
import { BaseButton, BaseModal } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { Modal } from "src/pages/product/product-types";
import { globalPartnerState } from "src/recoil/partners/atom";
import { useToast } from "src/recoil/toast/hook";
import ProviderBasicForm from "../components/ProviderBasicForm";
export interface Pd {
  providerDetail: ProviderDetailModel;
}
const BasicInfoForm = ({ providerDetail }: Pd) => {
  // 로딩바
  // 토스트
  const { openToast } = useToast();

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const providerId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  // 현재 파트너
  const globalPartner = useRecoilValue(globalPartnerState);

  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // 프로바이더 등록
  const { executeAsync: postProviderBasicInfoApi } = useApiOperation(postProviderBasicInfoAsync);

  //  프로바이더 수정
  const { executeAsync: editProviderApi } = useApiOperation(editProviderBasicInfoAsync);

  // 프로바이더 상세
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);

  // 프로바이더 등록 default state
  const defaultValues = useMemo(() => {
    const providerPostData: ProviderBasicInfoAddModel = {
      provider: {
        providerName: "",
        representative: "",
        businessRegistrationNumber: "",
        address: "",
        isDeleted: false,
        bizType: "",
        bizClass: "",
        supplyType: "",
        partnerId: String(globalPartner?.id),
      },
      contractMediaList: [],
    };

    return providerPostData;
  }, [globalPartner]);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ProviderBasicInfoAddModel>({
    defaultValues,
  });

  //사업자/법인 등록번호 검증
  const checkValidateOrgNumber = useCallback((number: string) => {
    if (!number) return false;

    const cleanNumber = number.replace(/-/gi, "");
    const numberMap: number[] = cleanNumber.split("").map((d) => parseInt(d, 10));

    // 10자리(사업자등록번호) 또는 13자리(법인등록번호) 검증
    if (numberMap.length === 10 || numberMap.length === 13) {
      return true;
    }

    return false;
  }, []);

  //  유효성 확인
  const formValidation = useCallback(() => {
    // 프로바이더 명
    register("provider.providerName", {
      required: { value: true, message: "사업자명을 입력해 주세요" },
    });

    // 사업자 등록번호
    register("provider.businessRegistrationNumber", {
      validate: {
        requried: (businessNumber) => {
          let result = true;
          let message = "";
          let validateBusinessNumber = checkValidateOrgNumber(String(businessNumber));

          if (!businessNumber) {
            result = false;
            message = "사업자 등록번호를 입력해 주세요";
          } else if (!validateBusinessNumber) {
            result = false;
            message = "올바르지 않은 등록 번호입니다.";
          }

          return result || message;
        },
      },
    });

    //  대표자 성명
    register("provider.representative", {
      required: { value: true, message: "대표자명을 입력해 주세요" },
    });

    // 주소
    register("provider.address", {
      required: { value: true, message: "사업장 주소지를 입력해 주세요" },
    });

    // 구분
    register("provider.supplyType", {
      required: { value: true, message: "유형을 선택해 주세요" },
    });

    // 사업자 등록증 사본
    register("contractMediaList", {
      validate: {
        requried: (value) => {
          console.log(`value`, value);
          let result = true;
          let message = "";

          if (!value?.length) {
            message = "사업자등록증을 추가해주세요";
            result = false;
          } else if (value?.length < 2 && value.some((item) => item.isDeleted)) {
            message = "사업자등록증을 추가해주세요";
            result = false;
          } else if (value.every((item) => item.isDeleted === true)) {
            message = "사업자등록증을 추가해주세요";
            result = false;
          }

          return result || message;
        },
      },
    });
  }, [watch]);

  useEffect(() => {
    formValidation();
  }, [formValidation]);

  useEffect(() => {
    if (providerDetail) {
      setValue("provider", providerDetail.provider);
      setValue("contractMediaList", providerDetail.contractMediaList);
    }
  }, [providerDetail, setValue]);

  // 유효성 검사후 저장
  const onSubmit = useCallback(async (data?: ProviderBasicInfoAddModel, e?: any) => {
    console.log("data", data);
    e.preventDefault();
    // setSubmitData(data);

    // 사업자등록증 삭제 후 post
    let newContractMedia: Array<any> = [];
    if (data && data.contractMediaList?.length) {
      for (let i = 0; i < data.contractMediaList.length; i++) {
        const media = data.contractMediaList[i];

        newContractMedia.push({
          ...media,
          serviceType: "CT_PROVIDER",
          serviceTag: "BUSINESS_LICENSE",
          isPrimary: true,
          orderNums: 0,
        });
      }
      data.contractMediaList = newContractMedia;
    }

    setConfirmModal({ message: "프로바이더 기본정보 저장하시겠습니까?", isOpen: true, payload: data });
  }, []);

  const onError = useCallback((errors: any, e?: any) => {
    console.log("onError errors", errors);
    return false;
  }, []);

  // 정상 등록/수정 진행 (팝업 확인)
  const onClickConfirmModal = async (data: ProviderBasicInfoAddModel) => {
    if (providerId) {
      const updateData: ProviderBasicInfoAddModel = {
        ...data,
        providerId,
      };
      console.log("updateData", updateData);
      const editResponse = await editProviderApi(updateData);

      if (editResponse.status >= 200 && editResponse.status <= 299) {
        openToast({ content: `정상적으로 ${providerId ? "수정" : "등록"}되었습니다.` });
        navigate(PagePath.provider.detail.replace(":id", `${editResponse.data.data.providerId}`));
      }
    } else {
      const response = await postProviderBasicInfoApi(data);

      if (response.status >= 200 && response.status <= 299) {
        openToast({ content: `정상적으로 ${providerId ? "수정" : "등록"}되었습니다.` });
        navigate(PagePath.provider.detail.replace(":id", `${response.data.data.providerId}`));
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__scroll">
          <div className="contents-container__wrap detail-basic-info">
            <div className="contents-container__wrap-article">
              <div className="contents-container__sub-title">
                <h2>기본정보</h2>
              </div>

              {/* 프로바이더 기본정보 */}
              <ProviderBasicForm
                providerId={String(providerId)}
                errors={errors}
                control={control}
                setValue={setValue}
                providerDetail={providerDetail}
              />
            </div>
          </div>
        </div>
        {/* 버튼영역 */}
        <div className="contents-container__btn-wrap">
          <div className="left-area">{!providerId && <GoToListButton />}</div>
          <div className="right-area">
            {providerId && (
              <BaseButton
                title="수정취소"
                className="color-white size-large mr5"
                onClick={() => {
                  if (providerId) {
                    navigate(PagePath.provider.detail.replace(":id", String(providerId)));
                  } else {
                    navigate(PagePath.provider.list);
                  }
                }}
              />
            )}
            <BaseButton type="submit" title="저장" className="size-large" />
          </div>
        </div>
      </form>
      <>
        {confirmModal.isOpen && (
          <BaseModal
            isOpen={true}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            title="저장하시겠습니까?"
            onClose={() => setConfirmModal({ isOpen: false })}
            onClick={() => {
              confirmModal.payload && onClickConfirmModal(confirmModal.payload);
              setConfirmModal({ isOpen: false });
            }}
          >
            {/* <p>{confirmModal.message}</p> */}
          </BaseModal>
        )}
      </>
    </>
  );
};

export default BasicInfoForm;
