import { Control, Controller } from "react-hook-form";
import { BaseInput, BaseTooltip } from "src/components";
import { ProviderContactFormType } from "src/pages/provider/provider-constants";

type Props = {
  control: Control<ProviderContactFormType>;
};

const CsContactForm = ({ control }: Props) => {
  return (
    <div>
      <article className="contents-container__wrap-article">
        <div className="contents-container__sub-title">
          <h2>연락처 정보</h2>
        </div>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <div className="flex-center">
              <p className="required">문의 접수 전화번호</p>
              <BaseTooltip className="ml2" touchIcon="QUESTION">
                <ul className="small-bullet pl15">
                  <li className="mb3">
                    <span>문의 접수를 위해 노출되는 연락처는 개인 휴대폰 번호를 사용할 수 없습니다.</span>
                  </li>
                  <li>
                    <span>해당 정보는 Taap, 알림톡, 이메일에 표시됩니다.</span>
                  </li>
                </ul>
              </BaseTooltip>
            </div>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <Controller
                control={control}
                name={`csContact.contact`}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                  return (
                    <BaseInput
                      type="phone"
                      onChange={(phone: string) => onChange(phone)}
                      value={value}
                      name={name}
                      placeholder="문의 접수를 위한 유선 전화번호를 입력해주세요"
                      errorText={error && error.message}
                    />
                  );
                }}
              ></Controller>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="required">문의 접수 이메일</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <Controller
                control={control}
                name={`csContact.email`}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                  return (
                    <BaseInput
                      onChange={onChange}
                      value={value}
                      name={name} //
                      placeholder="문의 접수를 위한 이메일 주소를 입력해주세요"
                      errorText={error && error.message}
                    />
                  );
                }}
              ></Controller>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="required">세금계산서 이메일</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <Controller
                control={control}
                name={`billingContact.email`}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                  return (
                    <BaseInput //
                      onChange={onChange}
                      value={value}
                      name={name}
                      placeholder="세금계산서 발행을 위한 이메일 주소를 입력해주세요"
                      errorText={error && error.message}
                    />
                  );
                }}
              ></Controller>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>세금계산서 전화번호</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <Controller
                control={control}
                name={`billingContact.contact`}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                  return (
                    <BaseInput //
                      type="phone"
                      onChange={(phone: string) => onChange(phone)}
                      value={value}
                      name={name}
                      placeholder="세금계산서 발행을 위한 전화번호를 입력해주세요"
                    />
                  );
                }}
              ></Controller>
            </div>
          </div>
        </section>
      </article>
    </div>
  );
};

export default CsContactForm;
