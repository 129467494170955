import moment from "moment";
import { useParams } from "react-router-dom";
import { Cell, Row } from "react-table";
import { UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { CommonFacilityModel } from "src/api/building/building-types";
import { AvailableReservationTime, ReservationPolicyClosedPeriod, ReservationPolicyMode, UnitReservationTime } from "src/api/product/product-api";
import NoImage from "src/assets/images/NoImage.png";
import { BaseButton, BaseTooltip } from "src/components";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import usePostCommonFacility from "src/hooks/product/usePostCommonFacility";
import { useQueryParams } from "src/hooks/useQueryParams";
import { PagePath } from "src/pages/product/details";
import { SpaceTypeLabel, SpaceTypeT } from "src/pages/product/product-types";
import { useModal } from "src/recoil/modalState/hook";
import { resizedImageUrl, ViewYmdFormat } from "src/utils";

import DefaultSettingModal from "../modal/DefaultSettingModal";
import { convertMinutesToDHMS, renderTime } from "../libs";
import { ContractFacilityTableData } from "src/pages/contract/commonComponents/reservation/components/ContractSpaceContents";
import { DetailTotalData } from "src/api/contract/contract-types";
import { deleteOperationFacilityAsync } from "src/api/contract/contract-facility-api";
import { useApiOperation } from "src/api/hooks";
import { useQueryClient } from "@tanstack/react-query";

export interface ReservationProps {
  row: Row<CommonFacilityModel> | Row<ContractFacilityTableData> | any; //상품 or 신청계약
  serviceType: UnionServiceType;
  serviceId: string;
  availableReservationTimeList: AvailableReservationTime[];
  unitReservationTimeList: UnitReservationTime[];
  reservationPolicyClosedPeriodList: ReservationPolicyClosedPeriod[];
  reservationPolicyModeList: ReservationPolicyMode[];

  detailData: DetailTotalData; //신청계약 전용
  [key: string]: any;
}

export const meeting_cols: any[] = [
  {
    Header: "회의실 id",
    accessor: "id",
    width: 80,
    sticky: "left",
  },
  {
    Header: "건물",
    accessor: "buildingName",
    width: 148,
    sticky: "left",
    Cell: ({ value, row }: Cell<any>) => {
      const path = PagePath.building.detail.replace(":id", row.original.buildingId);

      return <BaseNewTabLink path={path} value={value || "-"} tooltip></BaseNewTabLink>;
    },
  },
  {
    Header: "이미지",
    accessor: "img",
    width: 70,
    sticky: "left",

    Cell: ({ row, value }: Cell<any>) => {
      return (
        <div className="flex-center-center ">
          <img className="table_img" src={resizedImageUrl(row.original?.mediaList?.[0]?.url) || NoImage} alt=""></img>
        </div>
      );
    },
  },
  {
    Header: "회의실 명",
    accessor: "facilityName",
    sticky: "left",
    width: 148,
    Cell: ({ row, value }: any) => {
      const path = PagePath.building.detail.replace(":id", row.original.buildingId);

      return <BaseNewTabLink path={path + "?tab=facility#회의실"} value={value || "-"} tooltip></BaseNewTabLink>;
    },
  },
  {
    Header: "위치",
    accessor: "floorNum",
    sticky: "left",
    width: 96,
    Cell: ({ row, value }: Cell<any>) => {
      const isGround = row.original.isGround;
      return (
        <p>
          {isGround ? "지상" : "지하"} {value || "-"}층
        </p>
      );
    },
  },
];

export const refresh_cols: any[] = [
  {
    Header: "편의시설 id",
    accessor: "id",
    sticky: "left",
    width: 80,
  },
  {
    Header: "건물",
    accessor: "buildingName",
    sticky: "left",
    width: 148,
    Cell: ({ value, row }: Cell<any>) => {
      const path = PagePath.building.detail.replace(":id", row.original.buildingId);

      return <BaseNewTabLink path={path} value={value || "-"} tooltip></BaseNewTabLink>;
    },
  },
  {
    Header: "편의시설 명",
    accessor: "facilityName",
    sticky: "left",
    width: 148,
    Cell: ({ row, value }: Cell<any>) => {
      const path = PagePath.building.detail.replace(":id", row.original.buildingId);

      return <BaseNewTabLink path={path + "?tab=facility#편의시설"} value={value || "-"} tooltip></BaseNewTabLink>;
    },
  },

  {
    Header: "위치",
    accessor: "floorNum",
    sticky: "left",
    width: 80,
    Cell: ({ row, value }: Cell<any>) => {
      const isGround = row.original.isGround;
      return (
        <p>
          {isGround ? "지상" : "지하"} {value || "-"}층
        </p>
      );
    },
  },
];

export const desk_cols: any[] = [
  {
    Header: "id",
    accessor: "id",
    sticky: "left",
    width: 80,
  },
  {
    Header: "건물",
    accessor: "buildingName",
    sticky: "left",
    width: 148,
    Cell: ({ value, row }: Cell<any>) => {
      const path = PagePath.building.detail.replace(":id", row.original.buildingId);

      return <BaseNewTabLink path={path} value={value || "-"} tooltip></BaseNewTabLink>;
    },
  },
  {
    Header: "좌석그룹 명",
    accessor: "groupName",
    sticky: "left",
    width: 146,
    Cell: ({ row, value, serviceType }: ReservationProps) => {
      if (serviceType === "SERVICE_PRODUCT") {
        //상품
        return (
          <div className="flex-center-start w100">
            <BaseTooltip contents={value || "-"} />
          </div>
        );
      } else {
        //신청계약
        if ("buildingCommonFacilityDeskGroupName" in row.original) {
          //타입가드
          return (
            <div className="flex-center-start w100">
              <BaseTooltip contents={row?.original?.buildingCommonFacilityDeskGroupName || "-"} />
            </div>
          );
        }
      }
    },
  },
  {
    Header: "좌석 명",
    accessor: "facilityName",
    sticky: "left",
    width: 96,
    Cell: ({ row, value }: Cell<any>) => {
      const path = PagePath.building.detail.replace(":id", row.original.buildingId);

      return <BaseNewTabLink path={path + "?tab=facility#좌석"} value={value || "-"} tooltip></BaseNewTabLink>;
    },
  },
  {
    Header: "위치",
    accessor: "floorNum",
    sticky: "left",
    width: 80,
    Cell: ({ row, value }: Cell<any>) => {
      const isGround = row.original.isGround;
      return (
        <p>
          {isGround ? "지상" : "지하"} {value || "-"}층
        </p>
      );
    },
  },
];

export const duplicate_cols: any[] = [
  {
    Header: "예약 가능 기간",
    accessor: "time",
    width: 175,
    Cell: ({ row, availableReservationTimeList, reservationPolicyModeList }: ReservationProps) => {
      const PolicyMode = reservationPolicyModeList?.find(
        (data) =>
          String(data.buildingCommonFacilityId) === String(row.original?.id) &&
          data.reservationPolicyModeType === "RESERVATION_POLICY_MODE_AVAILABLE",
      );
      const availableReservationTime = availableReservationTimeList?.find(
        (data) => String(data.buildingCommonFacilityId) === String(row.original?.id),
      );

      const TimeStart = convertMinutesToDHMS(availableReservationTime?.availableReservationTimeStart);
      const TimeEnd = convertMinutesToDHMS(availableReservationTime?.availableReservationTimeEnd);

      if (PolicyMode?.isDefault !== false) {
        return <div>기본설정</div>;
      }

      return (
        <div>
          {!availableReservationTime?.isLimitedAvailableReservationTime ? (
            <div>무제한</div>
          ) : (
            <div className="w-100 flex-center-start">
              {TimeStart && <span>{TimeStart} 이후</span>}
              {TimeStart && TimeEnd && " - "}
              {TimeEnd && <span>{TimeEnd} 이내</span>}
            </div>
          )}
        </div>
      );
    },
  },
  {
    Header: "예약 시간 단위",
    accessor: "unit",
    width: 175,
    Cell: ({ row, unitReservationTimeList, reservationPolicyModeList }: ReservationProps) => {
      const PolicyMode = reservationPolicyModeList?.find(
        (data) =>
          String(data.buildingCommonFacilityId) === String(row.original?.id) && data.reservationPolicyModeType === "RESERVATION_POLICY_MODE_UNIT",
      );

      const unitReservationTime = unitReservationTimeList?.find((data) => String(data.buildingCommonFacilityId) === String(row.original?.id));

      const minTime = convertMinutesToDHMS(unitReservationTime?.minimumReservationTime);
      const maxTime = convertMinutesToDHMS(unitReservationTime?.maximumReservationTime);

      if (PolicyMode?.isDefault !== false) {
        return <div>기본설정</div>;
      }

      return (
        <div>
          {!unitReservationTimeList?.[0]?.isLimitedUnitReservationTime ? (
            <div>무제한</div>
          ) : (
            <div className="w-100 flex-center-start">
              {minTime && <span>최소{minTime}</span>}
              {minTime && maxTime && " - "}
              {maxTime && <span>최대{maxTime}</span>}
            </div>
          )}
        </div>
      );
    },
  },
  {
    Header: "휴무일",
    accessor: "closed",
    width: 240,
    Cell: ({ row, reservationPolicyClosedPeriodList, reservationPolicyModeList }: ReservationProps) => {
      const PolicyMode = reservationPolicyModeList?.find(
        (data) =>
          String(data.buildingCommonFacilityId) === String(row.original?.id) &&
          data.reservationPolicyModeType === "RESERVATION_POLICY_MODE_CLOSED_PERIOD",
      );

      const newReservationPolicyClosedPeriodList = reservationPolicyClosedPeriodList?.filter(
        (data) => String(data.buildingCommonFacilityId) === String(row.original?.id),
      );

      if (PolicyMode?.isDefault !== false) {
        return <div>기본설정</div>;
      }

      const arrText = newReservationPolicyClosedPeriodList.map((data) => {
        const startDate = moment(data?.startDate).format(ViewYmdFormat.YYYY_MM_DD);
        const endDate = moment(data?.endDate).format(ViewYmdFormat.YYYY_MM_DD);
        const closedStartTime = data?.startTime;
        const closedEndTime = data?.endTime;

        if (startDate === endDate) {
          return `${startDate} (${closedStartTime} - ${closedEndTime})`;
        }

        return `${startDate} - ${endDate} (${closedStartTime} - ${closedEndTime})`;
      });

      const sliceArrText = arrText.map((data) => {
        const indexNumber = data.indexOf("(");
        return data.slice(0, indexNumber);
      });

      return (
        <BaseTooltip
          contents={arrText.length > 0 ? sliceArrText.join(` `) : "-"}
          tooltip={
            (
              <ul className="base-list">
                {arrText.map((data) => (
                  <li key={data} className="font14">
                    {data}
                  </li>
                ))}
              </ul>
            ) as any as string
          }
          isSingleLine
        ></BaseTooltip>
      );
    },
  },
  {
    Header: "이용한도\n(월 이용한도 / 일 이용한도 / 초과예약)",
    accessor: "usage",
    width: 252,
    Cell: ({ row }: ReservationProps) => {
      const space = row?.original;

      return (
        <>
          {space && (
            <div className="d-flex gap5">
              <span>{renderTime("USED", space.monthlyServiceMinutes, space.isLimitedMonthlyReservation!)}</span>
              <span>/</span>

              <span>{renderTime("USED", space.dailyServiceMinutes, space.isLimitedDailyReservation!)}</span>
              <span>/</span>

              <span>{space.isAllowedOverReservation ? "허용" : "비허용"}</span>
            </div>
          )}
        </>
      );
    },
  },
];

export const time_court_price_cols = [];

export const time_court_edit_cols = [];

export const time_court_delete_cols = [];

export const edit_cols: any[] = [
  {
    Header: "예약 정책",
    accessor: "edit",
    width: 120,
    Cell: ({
      row,
      availableReservationTimeList,
      unitReservationTimeList,
      reservationPolicyClosedPeriodList,
      reservationPolicyModeList,
      serviceType,
      serviceId,

      detailData,
    }: ReservationProps) => {
      const { setAbstractModalZ1 } = useModal();
      const spaceType = row.original.commonFacilityType as SpaceTypeT;

      return (
        <div className="flex-row flex-center">
          <BaseButton
            title={"개별 설정"}
            className="color-white"
            disabled={
              detailData?.contract?.contractStep === "APPLY_CANCEL" ||
              detailData?.contract?.contractStep === "TERMINATE_COMPLETE" ||
              detailData?.contract?.contractStep === "APPLY_CONFIRM" ||
              detailData?.contract?.contractStep === "USE_COMPLETE"
            }
            onClick={() => {
              setAbstractModalZ1({
                size: "xlarge",
                className: "dialog-modal",
                isOpen: true,
                children: (
                  <DefaultSettingModal
                    spaceType={spaceType}
                    serviceType={serviceType}
                    serviceId={serviceId}
                    isDefault={false}
                    space={row?.original}
                    reservationPolicyModeList={reservationPolicyModeList}
                    detailData={detailData}
                  ></DefaultSettingModal>
                ),
              });
            }}
          />
        </div>
      );
    },
  },
];

export const delete_cols: any[] = [
  {
    Header: "삭제",
    accessor: "btn",
    width: 48,
    Cell: ({ row, detailData, serviceType }: ReservationProps) => {
      const { setBaseModal } = useModal();
      const { mutate } = usePostCommonFacility();
      const { queryParams } = useQueryParams<{ id?: string }>();
      const params = useParams();
      const productId = queryParams.id ? queryParams.id : params.id;
      const queryClient = useQueryClient();
      const { executeAsync: deleteOperationFacility } = useApiOperation(deleteOperationFacilityAsync);
      const spaceType = row.original.commonFacilityType as SpaceTypeT;

      if (serviceType === "SERVICE_PRODUCT") {
        return (
          <button
            className="base-trash-btn color-bg-gray"
            onClick={() => {
              setBaseModal({
                isOpen: true,
                btnLeftTitle: "취소",
                btnRightTitle: "확인",
                title: `해당 ${SpaceTypeLabel[spaceType]}을 삭제합니다`,
                children: `삭제한 즉시 해당 ${SpaceTypeLabel[spaceType]}에 엑세스 할 수 없습니다`,
                onClick: () => {
                  mutate({
                    productBuildingCommonFacilitySaveList: [
                      {
                        id: row?.original?.productBuildingCommonFacilityId,
                        cmdType: "D",
                        buildingCommonFacilityId: row.original?.id?.toString(),
                      },
                    ],
                    productId: productId!,
                  });

                  setBaseModal({ isOpen: false });
                },
              });
            }}
          ></button>
        );
      } else {
        return (
          <button
            className="base-trash-btn color-bg-gray"
            disabled={
              detailData?.contract?.contractStep === "APPLY_CANCEL" ||
              detailData?.contract?.contractStep === "TERMINATE_COMPLETE" ||
              detailData?.contract?.contractStep === "APPLY_CONFIRM" ||
              detailData?.contract?.contractStep === "USE_COMPLETE"
            }
            onClick={() => {
              setBaseModal({
                isOpen: true,
                btnLeftTitle: "취소",
                btnRightTitle: "확인",
                title: `해당 ${SpaceTypeLabel[spaceType]}을 삭제합니다`,
                children: `삭제한 즉시 해당 ${SpaceTypeLabel[spaceType]}에 엑세스 할 수 없습니다`,
                onClick: () => {
                  deleteOperationFacility({ contractId: detailData.contract?.contractId!, facilityId: row.original.id })
                    .then((res) => res.status === 200)
                    .finally(() => {
                      setBaseModal({ isOpen: false });
                      queryClient.refetchQueries({
                        queryKey: ["getReservationPolicyClosedPeriodAsync"],
                      });
                    });
                },
              });
            }}
          ></button>
        );
      }
    },
  },
];
