import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useApiOperation } from "src/api/hooks";
import { updateProductAsync } from "src/api/product/product-api";
import { CostType, ProductModel } from "src/api/product/product-types";
import { BaseButton, BaseInput, BaseModal, BaseRadio, BaseTextarea } from "src/components";

import useNavigate from "src/hooks/usePartnerNavigate";
import { useToast } from "src/recoil/toast/hook";

import { objectKeys } from "@toss/utils";
import { deleteCommas, inputPriceFormat, isString, numberToStringWithComma } from "src/utils";
import { getProductDetailPath } from "src/utils/route-util";
import { Modal, ProductCostTypeLabels } from "../../product-types";

import { MetaData } from "src/api/public-types";
import { BaseInputNumberFormat } from "src/components/BaseInputNumberFormat";

export type FormData = {
  deposit: string; //보증금
  earnest: string; //계약금
  balanceFullPayment: string;
  balanceFullPaymentDate: string; //잔금일자
  costType: CostType;
  productionCost: string; // 원가
  productionPrice: string; // new 판매가
  refundInfo: string; // 결제 및 취소 안내
  // rentalCostList: Array<MetaData>; // 판매가
};

interface IProps {
  product: ProductModel;
  productId: string;
}

/* 
  공간상품 등록 > 등록 or 수정 > 기본 정보
 */
const ChargeForm = ({ product: productDetail, productId }: IProps) => {
  const { openToast } = useToast();
  const navigate = useNavigate();

  const isEdit = isString(productId);

  const [submitData, setSubmitData] = useState<FormData>();

  // 모달 useState

  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // 공간상품 수정 Api
  const { executeAsync: updateProduct } = useApiOperation(updateProductAsync);

  /** 수정모드에서 프로바이더명 조회 후 setState */

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      deposit: productDetail.deposit ?? "",
      earnest: productDetail.earnest ?? "",
      balanceFullPaymentDate: productDetail.balanceFullPaymentDate ?? "", //잔금일자
      costType: productDetail.costType ?? "COST_UNRECOGNIZED", // 납부타입
      productionPrice: productDetail.productionPrice === -1 ? "" : String(productDetail.productionPrice ?? ""),
      productionCost: productDetail.productionCost === -1 ? "" : String(productDetail.productionCost ?? ""),
      refundInfo: productDetail.refundInfo ?? "",
    },
    mode: "onChange",
  });

  //  유효성 검사
  const formVaildation = useCallback(() => {
    const requiredMessage = "필수입력 항목입니다";

    // 공간임대 일 때 유효성
    if (productDetail.productType !== "TIME_COURT") {
      register("deposit", {
        required: {
          value: true,
          message: requiredMessage,
        },
      });
      register("earnest", {
        required: {
          value: true,
          message: requiredMessage,
        },
        validate: (earnest) => {
          const deposit = +deleteCommas(watch("deposit"));
          const isNegative = deposit < +deleteCommas(earnest);

          if (isNegative) {
            setValue("earnest", "0", { shouldDirty: true, shouldValidate: true });
            return "계약금은 보증금보다 큰 금액을 입력할 수 없습니다.";
          }
        },
      });
      register("balanceFullPaymentDate", {
        required: {
          value: true,
          message: requiredMessage,
        },
        min: 0,
      });
      register("costType", {
        required: {
          value: true,
          message: requiredMessage,
        },
        validate: (value) => {
          if (value === "COST_UNRECOGNIZED") return requiredMessage;
        },
      });
      register("productionPrice", {
        required: {
          value: true,
          message: requiredMessage,
        },
      });
      register("productionCost", {
        required: {
          value: true,
          message: requiredMessage,
        },
      });
    }
    register("refundInfo", {
      required: {
        value: true,
        message: requiredMessage,
      },
    });
  }, [register, productDetail.productType]);

  // react hook form 에서 사용하는 validation rules, error message 정의
  useEffect(() => {
    formVaildation();
  }, [formVaildation, productDetail]);

  // validation 통과 후 submit 될때 실행
  const onSubmit = useCallback(async (data: FormData, e?: any) => {
    e.preventDefault();
    console.log(`data`, data);
    setSubmitData(data);

    setConfirmModal({ message: "저장하시겠습니까?", isOpen: true });
  }, []);

  // 저장 / 수정
  const onClickConfirmModal = async (data: FormData) => {
    type UpdateProductData = {
      id: string;
      deposit: number; //보증금
      earnest: number; //계약금
      balanceFullPaymentDate: number; //잔금일자
      costType: CostType;
      productionCost: string; // 원가
      rentalCostList: Array<MetaData>; // 판매가
      refundInfo: string; // 결제 및 취소 안내
      productionPriceType: string; // new 판매가 유형
      productionPrice: number; // new 판매가
    };

    const updateProductData: UpdateProductData = {
      id: productId,
      deposit: +deleteCommas(data.deposit),
      earnest: +deleteCommas(data.earnest),
      balanceFullPaymentDate: +data.balanceFullPaymentDate,
      costType: data.costType,
      productionCost: deleteCommas(data.productionCost),
      refundInfo: data.refundInfo,

      rentalCostList: [
        // 임시로 기존 판매가 필드 유지
        {
          id: productDetail.rentalCostList?.[0]?.id,
          metaItem: "PRODUCT_RENT_MONTH_1", //고정값
          value1: deleteCommas(data.productionPrice),
          orderNums: 1, //고정값
        },
      ],
      productionPriceType: "PRODUCT_RENT_MONTH_1",
      productionPrice: +deleteCommas(data.productionPrice), // 새로운 판매가
    };

    const response = await updateProduct({ product: updateProductData });

    const result = response.data?.data?.content;
    if (result) {
      openToast({
        content: `정상적으로 수정 되었습니다.`,
      });
      navigate(
        getProductDetailPath({
          productId: productId + "?tab=charge",
        }),
      );
    }
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any) => {
    console.log("errors :>> ", errors);
  };

  return (
    <>
      <div className="contents-container__scroll">
        <form onSubmit={handleSubmit(onSubmit, onError)} id="productForm">
          {productDetail?.productType !== "TIME_COURT" && (
            <div className="contents-container__wrap">
              {/* 보증금 */}
              <div className="contents-container__wrap-contents">
                <div className="contents-container__sub-title">
                  <div className="pb4">
                    <h2>요금 관리</h2>
                    <ul className="base-list pt10">
                      <li>부가세 제외 금액을 입력해주세요.</li>
                    </ul>
                  </div>
                </div>

                <section className="contents-container__grid contents-container__1200">
                  <div className="contents-container__grid-index">
                    <p className="required">보증금</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <table className="inner-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="">보증금 (원)</div>
                          </th>
                          <th>
                            <div className="">계약금 (원)</div>
                          </th>
                          <th>
                            <div className="minmax420">잔금/일자 (원)</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="minmax220 flex-center no-wrap mr10">
                              <Controller
                                control={control}
                                name="deposit"
                                render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => {
                                  return (
                                    <BaseInputNumberFormat
                                      inputRef={ref}
                                      type="text"
                                      onChange={(text: string) => {
                                        onChange(text);
                                      }}
                                      value={inputPriceFormat(value)}
                                      name={name}
                                      placeholder="보증금을 입력해주세요"
                                    />
                                  );
                                }}
                              ></Controller>
                            </div>
                          </td>
                          <td>
                            <div className="minmax220 flex-center no-wrap mr10">
                              <Controller
                                control={control}
                                name="earnest"
                                render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => {
                                  return (
                                    <BaseInputNumberFormat
                                      inputRef={ref}
                                      type="text"
                                      onChange={(text: string) => {
                                        onChange(text);
                                      }}
                                      value={inputPriceFormat(value) || ""}
                                      name={name}
                                      placeholder="계약금을 입력해주세요"
                                    />
                                  );
                                }}
                              ></Controller>
                            </div>
                          </td>
                          <td>
                            <div className="flex-center no-wrap">
                              <Controller
                                control={control}
                                name="balanceFullPayment"
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                                  let deposit = +deleteCommas(watch("deposit"));
                                  let earnest = +deleteCommas(watch("earnest"));

                                  value = (deposit - earnest).toString();

                                  return <BaseInputNumberFormat type="text" className="minmax240" value={numberToStringWithComma(+value)} readonly />;
                                }}
                              ></Controller>
                              <span className="mx10">/ 계약 후</span>
                              <div className="minmax60">
                                <Controller
                                  control={control}
                                  name="balanceFullPaymentDate"
                                  render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => {
                                    return (
                                      <BaseInput
                                        type="number"
                                        onChange={(value: number) => {
                                          if (value < 0) {
                                            return;
                                          }
                                          onChange(value);
                                        }}
                                        value={value}
                                        name={name}
                                        inputRef={ref}
                                      />
                                    );
                                  }}
                                ></Controller>
                              </div>
                              <span className="mx10">일 이내</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {(errors?.deposit || errors?.earnest || errors?.balanceFullPaymentDate) && (
                      <p className="validation-text">
                        {errors?.deposit?.message || errors?.earnest?.message || errors?.balanceFullPaymentDate?.message}
                      </p>
                    )}
                  </div>
                </section>
              </div>
              {/* 이용료  */}
              <div className="contents-container__wrap-contents pt0">
                <section className="contents-container__grid contents-container__1200">
                  <div className="contents-container__grid-index">
                    <p className="required">이용료 납부유형</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <Controller
                      control={control}
                      name="costType"
                      render={({ field }) => {
                        return (
                          <div className="flex-row flex-center-start pb18">
                            {objectKeys(ProductCostTypeLabels).map((value) => (
                              <BaseRadio
                                key={value}
                                id={value}
                                name={value}
                                label={ProductCostTypeLabels[value]}
                                onChange={(e) => {
                                  if (e) {
                                    setValue("costType", value, { shouldValidate: true });
                                  }
                                }}
                                checked={field.value === value}
                                value={value}
                                className="mr16"
                              />
                            ))}
                            {errors?.costType && <p className="validation-text">{errors?.costType?.message}</p>}
                          </div>
                        );
                      }}
                    />

                    <table className="inner-table">
                      <thead>
                        <tr>
                          {watch("costType") === "COST_BILLING_MONTHLY" && (
                            <th>
                              <div>기준일</div>
                            </th>
                          )}
                          <th>
                            <div>원가(원)</div>
                          </th>
                          <th>
                            <div>판매가(원)</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {watch("costType") === "COST_BILLING_MONTHLY" && (
                            <td>
                              <div className="minmax220 flex-center-center no-wrap">
                                <span className="ml10 mr16 text-center">1개월</span>
                              </div>
                            </td>
                          )}

                          <td>
                            <div className="minmax220 flex-center no-wrap mr10">
                              <Controller
                                control={control}
                                name="productionCost" //
                                render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => {
                                  return (
                                    <BaseInputNumberFormat
                                      inputRef={ref}
                                      type="text"
                                      onChange={onChange}
                                      value={inputPriceFormat(value)}
                                      name={name}
                                    />
                                  );
                                }}
                              ></Controller>
                            </div>
                          </td>
                          <td>
                            <div className="minmax220 flex-center no-wrap">
                              <Controller
                                control={control}
                                name="productionPrice" //
                                render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => {
                                  return (
                                    <BaseInputNumberFormat
                                      inputRef={ref}
                                      type="text"
                                      onChange={onChange}
                                      value={inputPriceFormat(value)}
                                      name={name}
                                    />
                                  );
                                }}
                              ></Controller>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {errors?.productionPrice && <p className="validation-text">{errors?.productionPrice?.message}</p>}
                  </div>
                </section>
              </div>
            </div>
          )}

          <div className="contents-container__wrap-article">
            <div className="contents-container__sub-title">
              <div className="minmax140 pb4">
                <h2>결제 관리</h2>
              </div>
            </div>

            {/* 결제 취소 안내 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">결제 및 취소 안내</p>
              </div>
              <div className="contents-container__grid-contents">
                <Controller
                  control={control}
                  name="refundInfo"
                  render={({ field: { name, value, onChange }, fieldState: { error } }) => {
                    return (
                      <BaseTextarea
                        className="mr8"
                        onChange={onChange}
                        value={value}
                        name={name}
                        maxLength={1000}
                        height={144}
                        placeholder="내용을 입력해 주세요."
                        errorText={error?.message}
                      />
                    );
                  }}
                />
              </div>
            </section>
          </div>
        </form>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area"></div>
        <div className="right-area">
          {isEdit && (
            <BaseButton
              title="수정취소"
              className="mr10 color-white size-large"
              onClick={() => {
                navigate(
                  getProductDetailPath({
                    productId: productId + "?tab=charge",
                  }),
                );
              }}
            />
          )}
          <BaseButton type="submit" title="저장" className="size-large" form="productForm" />
        </div>
      </div>
      <>
        {/* 컨펌 모달 */}
        <BaseModal
          isOpen={confirmModal.isOpen}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          onClose={() => setConfirmModal({ isOpen: false })}
          onClick={() => submitData && onClickConfirmModal(submitData)}
          title={confirmModal.message}
        ></BaseModal>
      </>
    </>
  );
};

export default ChargeForm;
