import { ReactChild } from "react";
import { ADMIN_MEMO, MediaServiceType, MetaGroupType } from "src/api/public-types";

// modal 공통
export type Modal = {
  isOpen: boolean;
  children?: ReactChild;
  message?: string;
  type?: string;
  payload?: any;
  title?: string;
};

export interface BuildingFloorAndHo {
  roomId: string;
  buildingName: string | undefined;
  roomNum: number;
  floorNum: number;
  leasableAreaNet: string;
  leasableArea: string;
  rateOfUse: number;
  buildingId: string;
  floorId: string;
  isPrimary: boolean;
}

export type PropertyType = {
  floorId: number;
  floorNum: number;
  isPrimary: boolean;
  leasableArea: string;
  leasableAreaNet: string;
  roomId: number;
  roomNum: number;
  buildingId: number;
};

// 공간상품 상품정보 이용료

export enum ProductRent {
  MONTH = "PRODUCT_RENT_MONTH_1",
  HOUR = "PRODUCT_RENT_HOUR_1",
  MIUNUTE = "PRODUCT_RENT_MINUTE_10",
}

// 공간상품 상품정보 이용료 할인
export enum ProductRentDiscount {
  MONTH = "PRODUCT_RENT_DISCOUNT_MONTH_1",
  HOUR = "PRODUCT_RENT_DISCOUNT_HOUR_1",
  MIUNUTE = "PRODUCT_RENT_DISCOUNT_MINUTE_10",
}

// 공간상품 이용료
export const productRent = [{ metaItem: ProductRent.MONTH, value1: "", orderNums: 1 }]; //1개월

export const productTimeRent = [{ metaItem: ProductRent.MIUNUTE, value1: "", orderNums: 1 }]; //타임코트 10분

//  공간상품 이용료 할인
export const productSaleRent = [{ metaItem: ProductRentDiscount.MONTH, value1: "" }]; // 1개월
export const productTimeSaleRent = [{ metaItem: ProductRentDiscount.MIUNUTE, value1: "" }]; //타임코트 10분

// 공간상품 상품정보 관리비
export const productMaintainceFee = [
  {
    metaGroup: "PRODUCT_MAINTENANCE_FEE" as MetaGroupType,
    metaItem: "PRODUCT_MAINTENANCE_FEE_1",
    value1: "true",
    orderNums: 1,
  },
  {
    metaGroup: "PRODUCT_MAINTENANCE_FEE" as MetaGroupType,
    metaItem: "PRODUCT_MAINTENANCE_FEE_2",
    value1: "true",
    orderNums: 2,
  },
  {
    metaGroup: "PRODUCT_MAINTENANCE_FEE" as MetaGroupType,
    metaItem: "PRODUCT_MAINTENANCE_FEE_3",
    value1: "true",
    orderNums: 3,
  },
  {
    metaGroup: "PRODUCT_MAINTENANCE_FEE" as MetaGroupType,
    metaItem: "PRODUCT_MAINTENANCE_FEE_4",
    value1: "true",
    orderNums: 4,
  },
  {
    metaGroup: "PRODUCT_MAINTENANCE_FEE" as MetaGroupType,
    metaItem: "PRODUCT_MAINTENANCE_FEE_5",
    value1: "true",
    orderNums: 5,
  },
  {
    metaGroup: "PRODUCT_MAINTENANCE_FEE" as MetaGroupType,
    metaItem: "PRODUCT_MAINTENANCE_FEE_6",
    value1: "true",
    orderNums: 6,
  },
  {
    metaGroup: "PRODUCT_MAINTENANCE_FEE" as MetaGroupType,
    metaItem: "PRODUCT_MAINTENANCE_FEE_7",
    value1: "true",
    orderNums: 7,
  },
  {
    metaGroup: "PRODUCT_MAINTENANCE_FEE" as MetaGroupType,
    metaItem: "PRODUCT_MAINTENANCE_FEE_8",
    value1: "true",
    orderNums: 8,
  },
  {
    metaGroup: "PRODUCT_MAINTENANCE_FEE" as MetaGroupType,
    metaItem: "PRODUCT_MAINTENANCE_FEE_9",
    value1: "true",
    orderNums: 9,
  },
];

// 공간상품 기본정보 방향
export const productDirections = [
  {
    value: {
      metaGroup: "PRODUCT_DIRECTION" as MetaGroupType,
      metaItem: "PRODUCT_DIRECTION_E",
      value1: "false",
      orderNums: 1,
    },
    label: "동",
  },
  {
    value: {
      metaGroup: "PRODUCT_DIRECTION" as MetaGroupType,
      metaItem: "PRODUCT_DIRECTION_W",
      value1: "false",
      orderNums: 2,
    },
    label: "서",
  },
  {
    value: {
      metaGroup: "PRODUCT_DIRECTION" as MetaGroupType,
      metaItem: "PRODUCT_DIRECTION_S",
      value1: "false",
      orderNums: 3,
    },
    label: "남",
  },
  {
    value: {
      metaGroup: "PRODUCT_DIRECTION" as MetaGroupType,
      metaItem: "PRODUCT_DIRECTION_N",
      value1: "false",
      orderNums: 4,
    },
    label: "북",
  },
  {
    value: {
      metaGroup: "PRODUCT_DIRECTION" as MetaGroupType,
      metaItem: "PRODUCT_DIRECTION_SE",
      value1: "false",
      orderNums: 5,
    },
    label: "남동",
  },
  {
    value: {
      metaGroup: "PRODUCT_DIRECTION" as MetaGroupType,
      metaItem: "PRODUCT_DIRECTION_SW",
      value1: "false",
      orderNums: 6,
    },
    label: "남서",
  },
  {
    value: {
      metaGroup: "PRODUCT_DIRECTION" as MetaGroupType,
      metaItem: "PRODUCT_DIRECTION_NE",
      value1: "false",
      orderNums: 7,
    },
    label: "북동",
  },
  {
    value: {
      metaGroup: "PRODUCT_DIRECTION" as MetaGroupType,
      metaItem: "PRODUCT_DIRECTION_NW",
      value1: "false",
      orderNums: 8,
    },
    label: "북서",
  },
];

export interface ImageRequestModal {
  serviceId: number;
  mediaServiceTypes: Array<MediaServiceType>;
}

// 공간상품 이미지 리스트 전체 불러오기
export const productMediaServiceType: Array<MediaServiceType> = [
  "MEDIA_PRODUCT_OFFICE",
  "MEDIA_PRODUCT_FLOOR_PLAN",
  "MEDIA_PRODUCT_MEETING_ROOM",
  "MEDIA_PRODUCT_DESK",
  "MEDIA_PRODUCT_REFRESH_ROOM",
  "MEDIA_PRODUCT_TOILET",
  "MEDIA_PRODUCT_PARKING_LOT",
  "MEDIA_PRODUCT_FACILITY",
  "MEDIA_PRODUCT_ETC",
];

export const PRODUCT_CATEGORY_MAP = {
  PRODUCT_CATEGORY_NORMAL: "일반",
  PRODUCT_CATEGORY_MANAGEMENT: "관리비 계약 상품",
};

export const ProductTypeLabels = {
  FULL_COURT: "공간임대", //F2
  TIME_COURT: "공간예약", //S2
  NON_RESIDENT_OFFICE: "비상주", //V
  MAINTENANCE_FEE: "관리비", //M
  DIGITAL_PRODUCT: "디지털", //D
} as const;

export const ProductTypes = {
  FULL_COURT: "FULL_COURT",
  TIME_COURT: "TIME_COURT",
  NON_RESIDENT_OFFICE: "NON_RESIDENT_OFFICE",
  MAINTENANCE_FEE: "MAINTENANCE_FEE",
  DIGITAL_PRODUCT: "DIGITAL_PRODUCT",
} as const;

export type ProductTypeT = keyof typeof ProductTypes;

export const ProductStatusLabels = {
  ENABLED: "공개", // 공개
  DISABLED: "비공개", // 비공개
  LINK_ENABLED: "링크공개", // 이용중
};

export const ProductStatus = {
  ENABLED: "ENABLED", // 공개
  DISABLED: "DISABLED", // 비공개
  LINK_ENABLED: "LINK_ENABLED", // 이용중
} as const;

export type ProductStatusT = keyof typeof ProductStatus;

export const ProductIconTypeLabels = {
  PRODUCT_ICON_NEW: "NEW", //NEW 아이콘
  PRODUCT_ICON_BEST: "BEST", //BEST 아이콘
  PRODUCT_ICON_SALE: "SALE", //SALE 아이콘
};

export const ProductIconType = {
  PRODUCT_ICON_UNRECOGNIZED: "PRODUCT_ICON_UNRECOGNIZED", //정의되지 않은 타입 DEFAULT
  PRODUCT_ICON_NEW: "PRODUCT_ICON_NEW", //NEW 아이콘
  PRODUCT_ICON_BEST: "PRODUCT_ICON_BEST", //BEST 아이콘
  PRODUCT_ICON_SALE: "PRODUCT_ICON_SALE", //SALE 아이콘
} as const;

export type ProductIconTypeT = keyof typeof ProductIconType;

export const tabs = [
  { value: "basicInfo", label: "기본정보" },
  { value: "productInfo", label: "전시정보 " },
  { value: "image", label: "이미지" },
  { value: "charge", label: "요금/결제" },
  { value: "commonSpace", label: "공용공간" },
  { value: "accessLevel", label: "출입그룹" },
  { value: "guide", label: "이용안내" },
  { value: "iotDevice", label: "IoT 기기" },
  ...(process.env.REACT_APP_ENVIRONMENT === "dev" ? [{ value: "history", label: "변경내역" }] : []),
];

export const ProductCostTypeLabels = {
  COST_BILLING_ONE_TIME: "일시 납부", //NEW 아이콘
  COST_BILLING_MONTHLY: "정기 납부", //BEST 아이콘
};

export const SpaceType = {
  MEETING_ROOM: "MEETING_ROOM",
  DESK: "DESK",
  REFRESH_ROOM: "REFRESH_ROOM",
} as const;

export type SpaceTypeT = keyof typeof SpaceType;

export const SpaceTypeLabel = {
  MEETING_ROOM: "회의실",
  DESK: "좌석",
  REFRESH_ROOM: "편의시설 ",
};

// 검색대상
export const searchTypeOptions = [
  { value: "ALL", label: "전체" },
  { value: "PRODUCT_NAME", label: "상품명" },
  { value: "ADDRESS", label: "주소" },
  { value: "BUILDING_NAME", label: "건물명" },
];

// 상태
export const statusOptions = [
  { value: "", label: "전체" },
  { value: ProductStatus.ENABLED, label: "공개" },
  { value: ProductStatus.DISABLED, label: "비공개" },
  { value: ProductStatus.LINK_ENABLED, label: "링크공개" },
];

// 이용여부
export const isProductsUsedOptions = [
  { value: "", label: "전체" },
  { value: "true", label: "이용중" },
  { value: "false", label: "미이용" },
];

// 상품 유형
export const productTypeOptions = [
  { value: "", label: "전체" },
  { value: ProductTypes["FULL_COURT"], label: ProductTypeLabels["FULL_COURT"] },
  { value: ProductTypes["TIME_COURT"], label: ProductTypeLabels["TIME_COURT"] },
  { value: ProductTypes["MAINTENANCE_FEE"], label: ProductTypeLabels["MAINTENANCE_FEE"] },
  { value: ProductTypes["NON_RESIDENT_OFFICE"], label: ProductTypeLabels["NON_RESIDENT_OFFICE"] },
  { value: ProductTypes["DIGITAL_PRODUCT"], label: ProductTypeLabels["DIGITAL_PRODUCT"] },
];

export const timeSelectLabel = [
  // {
  //   value: "",
  //   label: "",
  // },
  {
    value: "30",
    label: "30분",
  },
  {
    value: "60",
    label: "1시간",
  },
  {
    value: "90",
    label: "1시간 30분",
  },
  {
    value: "120",
    label: "2시간",
  },
  {
    value: "150",
    label: "2시간 30분",
  },
  {
    value: "180",
    label: "3시간",
  },
  {
    value: "210",
    label: "3시간 30분",
  },
  {
    value: "240",
    label: "4시간",
  },
  {
    value: "270",
    label: "4시간 30분",
  },
  {
    value: "300",
    label: "5시간",
  },
  {
    value: "330",
    label: "5시간 30분",
  },
  {
    value: "360",
    label: "6시간",
  },
  {
    value: "390",
    label: "6시간 30분",
  },
  {
    value: "420",
    label: "7시간",
  },
  {
    value: "450",
    label: "7시간 30분",
  },
  {
    value: "480",
    label: "8시간",
  },
  {
    value: "510",
    label: "8시간 30분",
  },
  {
    value: "540",
    label: "9시간",
  },
  {
    value: "570",
    label: "9시간 30분",
  },
  {
    value: "600",
    label: "10시간",
  },
  {
    value: "630",
    label: "10시간 30분",
  },
  {
    value: "660",
    label: "11시간",
  },
  {
    value: "690",
    label: "11시간 30분",
  },
  {
    value: "720",
    label: "12시간",
  },
  {
    value: "750",
    label: "12시간 30분",
  },
  {
    value: "780",
    label: "13시간",
  },
  {
    value: "810",
    label: "13시간 30분",
  },
  {
    value: "840",
    label: "14시간",
  },
  {
    value: "870",
    label: "14시간 30분",
  },
  {
    value: "900",
    label: "15시간",
  },
  {
    value: "930",
    label: "15시간 30분",
  },
  {
    value: "960",
    label: "16시간",
  },
  {
    value: "990",
    label: "16시간 30분",
  },
  {
    value: "1020",
    label: "17시간",
  },
  {
    value: "1050",
    label: "17시간 30분",
  },
  {
    value: "1080",
    label: "18시간",
  },
  {
    value: "1110",
    label: "18시간 30분",
  },
  {
    value: "1140",
    label: "19시간",
  },
  {
    value: "1170",
    label: "19시간 30분",
  },
  {
    value: "1200",
    label: "20시간",
  },
  {
    value: "1230",
    label: "20시간 30분",
  },
  {
    value: "1260",
    label: "21시간",
  },
  {
    value: "1290",
    label: "21시간 30분",
  },
  {
    value: "1320",
    label: "22시간",
  },
  {
    value: "1350",
    label: "22시간 30분",
  },
  {
    value: "1380",
    label: "23시간",
  },
  {
    value: "1410",
    label: "23시간 30분",
  },
  {
    value: "1440",
    label: "24시간",
  },
];
