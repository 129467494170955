import { useEffect, useMemo, useRef, useState } from "react";
import { useBlockLayout, useTable } from "react-table";
import { BuildingDeviceControl, ContractDeviceControl, DeviceControlParams, UnionDeviceControlMethod } from "src/api/iot/iot-types";
import { Modal } from "src/api/public-types";
import { BaseInput, BaseModal } from "src/components";
import DeviceControlColumns from "../columns/DeviceControlColumns";
import { UnionPartnerPrivilegeCode } from "src/types/partner";

type Props = {
  controlList: BuildingDeviceControl[];
  excuteDevice: (passData: DeviceControlParams) => Promise<void>;
  checkExecuteAuthority: (deviceType: UnionPartnerPrivilegeCode) => boolean;
  onClickControl?: (item: ContractDeviceControl) => void;
};

type ExecuteData = {
  controlId: string;
  buildingDeviceId: string;
  productDeviceId: string;
  contractDeviceId: string;
  controlMethod: UnionDeviceControlMethod;
  componentId: string;
  deviceId: string;
  deviceType: string;
};

const ControlDeviceTable = ({ controlList, excuteDevice, onClickControl, checkExecuteAuthority }: Props) => {
  //  계약 iot 탭이면 실행권한 컬럼 노출

  const inputRef = useRef<HTMLInputElement>(null);

  const [argumentsValue, setArgumentsValue] = useState("");
  const [controlModal, setControlModal] = useState<Modal>({ isOpen: false });

  const [executeData, setExecuteData] = useState<ExecuteData>({
    controlId: "",
    buildingDeviceId: "",
    productDeviceId: "",
    contractDeviceId: "",
    controlMethod: "",
    componentId: "",
    deviceId: "",
    deviceType: "",
  });

  // 실행 팝업 오픈시 input에 포커스
  useEffect(() => {
    if (!controlModal.isOpen) return;
    inputRef.current?.focus();
  }, [controlModal.isOpen]);

  const columns: any = useMemo(() => {
    const hasContractIot = controlList.some((item: any) => item.contractDeviceId);
    const hasBuildingIot = controlList.some((item: any) => item.buildingDeviceId);

    let filteredColumns = DeviceControlColumns;

    // if (!hasBuildingIot) {
    //   filteredColumns = filteredColumns.filter((item) => item.Header !== "Labels");
    // }

    if (!hasContractIot) {
      filteredColumns = filteredColumns.filter((item) => item.Header !== "실행권한");
    }

    return filteredColumns;
  }, [controlList]);

  // 테이블에 실행 버튼 클릭시 모달 오픈
  const openControlModal = (data: any) => {
    setControlModal({ isOpen: true });
    setExecuteData(data);
  };

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data: controlList,
      excuteDevice,
      checkExecuteAuthority,
      onClickControl,
      openControlModal,
      setArgumentsValue,
    },
    useBlockLayout,
  );

  // 모달에서 arguments 작성하고 실행 버튼 클릭시 호출
  const handleExecuteDevice = () => {
    let newArguments: string | number = "";
    const isNumber = !isNaN(Number(argumentsValue));
    const isIncludedDoubleQuotes = argumentsValue && !isNumber && argumentsValue.includes('"');
    const isIncludedSingleQuotes = argumentsValue && !isNumber && argumentsValue.includes("'");
    const isIncludedBacktick = argumentsValue && !isNumber && argumentsValue.includes("`");

    // value 없으면 그냥 빈값 전송
    if (!argumentsValue || (typeof argumentsValue === "string" && argumentsValue.trim() === "")) {
      excuteDevice({
        deviceType: executeData.deviceType,
        deviceId: executeData.deviceId,
        controlId: executeData.controlId,
        controlMethod: executeData.controlMethod,
        arguments: "",
      });
      setControlModal({ isOpen: false });
      return;
    }

    // 타입이 number면 그대로 number 타입으로 전송
    if (isNumber) {
      newArguments = Number(argumentsValue);
    }
    // 문자열 변환하는 기호가 들어가있으면 replaceAll 해서 전송
    else if (!isNumber && (isIncludedDoubleQuotes || isIncludedSingleQuotes || isIncludedBacktick)) {
      newArguments = isIncludedDoubleQuotes
        ? argumentsValue.replaceAll('"', "")
        : isIncludedSingleQuotes
        ? argumentsValue.replaceAll("'", "")
        : isIncludedBacktick
        ? argumentsValue.replaceAll("`", "")
        : argumentsValue;
    } else {
      newArguments = argumentsValue;
    }

    excuteDevice({
      deviceType: executeData.deviceType,
      deviceId: executeData.deviceId,
      controlId: executeData.controlId,
      controlMethod: executeData.controlMethod,
      arguments: newArguments,
    });
    setArgumentsValue("");
    setControlModal({ isOpen: false });
  };

  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);
  return (
    <>
      {controlModal.isOpen && (
        <BaseModal
          btnLeftTitle="취소"
          btnRightTitle="실행" //
          isOpen={true}
          onClick={handleExecuteDevice}
          onClose={() => {
            setControlModal({ isOpen: false });
            setArgumentsValue("");
          }}
        >
          <div>
            <h2 className="text-left">제어실행</h2>
            <div className="flex-center-between mt20">
              <p className="minmax80 text-left">제어명</p>
              <p className="ellipsis">{executeData?.componentId}</p>
            </div>
            <div className="flex-center-between mt20">
              <p className="minmax80 text-left">arguments</p>
              <BaseInput
                inputRef={inputRef}
                className="minmax180 text-right"
                value={String(argumentsValue)}
                onChange={(value: string) => {
                  setArgumentsValue(value);
                }}
              />
            </div>
          </div>
        </BaseModal>
      )}
      <section className="inner-tab-table">
        <div {...getTableProps()} className="base-table view-data-table sticky">
          <div className="header">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                {headerGroup.headers.map((header) => {
                  return (
                    <div {...header.getHeaderProps()} className="base-table__th">
                      {header.render("Header")}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>

          <div {...getTableBodyProps()} className="body">
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                  {row.cells.map((cell: any) => {
                    return (
                      <div {...cell.getCellProps()} className="base-table__td">
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            <div>
              {rows.length === 0 && (
                <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                  <div className="base-table__td w-100 text-center">
                    <div className="w-100">{<span>데이터가 없습니다.</span>}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ControlDeviceTable;
