import { Dispatch, SetStateAction, useCallback } from "react";
import { Control, Controller, UseFormRegister, useFieldArray } from "react-hook-form";
import { ProviderContactModel } from "src/api/provider/provider-types";
import { BaseInput } from "src/components";
import { ProviderContactFormType } from "src/pages/provider/provider-constants";
import { BULLET, onlyNumber } from "src/utils";

type Props = {
  control: Control<ProviderContactFormType>;
  register: UseFormRegister<ProviderContactFormType>;
  errors?: any;
  deletedContact: Array<ProviderContactModel>;
  setDeletedContact: Dispatch<SetStateAction<Array<ProviderContactModel>>>;
};

const ContractContactForm = ({ control, deletedContact, setDeletedContact, errors, register }: Props) => {
  const {
    fields: contractContactList,
    append: addContract,
    remove,
  } = useFieldArray({
    control,
    name: `contractContactList`,
  });

  const errorText = useCallback((errorsData: any) => {
    if (errorsData.contractContactList) {
      const error = errorsData.contractContactList.filter((item: any) => item !== "empty");

      for (let i = 0; i < error.length; i++) {
        const errorType = error[i];
        if (errorType?.name) {
          return <p className="validation-text">{errorType?.name?.message}</p>;
        }

        if (errorType?.email) {
          return <p className="validation-text">{errorType?.email?.message}</p>;
        }

        if (errorType?.contact) {
          return <p className="validation-text">{errorType?.contact?.message}</p>;
        }
      }
    }
  }, []);

  return (
    <article className="contents-container__wrap-article">
      <div className="contents-container__sub-title pb10">
        <h2>계약 진행 담당</h2>
      </div>
      <div className="ml10 mb20 text-primary3 font14">
        <p>{BULLET} 계약 체결 요청, 계약 종료 안내 등과 관련된 고객 문의를 처리할 담당자의 정보입니다.</p>
        <p>{BULLET} 해당 정보는 계약 관련하여 고객과의 직접적인 소통을 위해 알림톡에 사용됩니다.</p>
      </div>
      <section className="contents-container__1200">
        <table
          className="inner-table"
          width="660" //
        >
          <thead className="border-y">
            <tr className="border-y">
              <th className="bg-white ">
                <span>No</span>
              </th>
              <th className="bg-white ">
                <span className="required mr10">이름</span>
              </th>
              <th className="bg-white ">
                <span className="required mr10">연락처</span>
              </th>
              <th className="bg-white ">
                <span className="required">이메일</span>
              </th>
              <th className="bg-white "></th>
            </tr>
          </thead>
          <tbody>
            {contractContactList &&
              contractContactList.map((item: ProviderContactModel & { id: string }, contactIdx: number) => {
                return (
                  <tr key={item.id}>
                    <td width="80">
                      <div className="flex-center-center">
                        <span>{contactIdx + 1}</span>
                      </div>
                    </td>
                    <td width="130">
                      <div className="mr10">
                        <Controller
                          control={control}
                          name={`contractContactList.${contactIdx}.name`}
                          render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                            return <BaseInput onChange={onChange} value={value} name={name} placeholder="이름" />;
                          }}
                        ></Controller>{" "}
                      </div>
                    </td>
                    <td width="170">
                      <div className="mr10">
                        <Controller
                          control={control}
                          name={`contractContactList.${contactIdx}.contact`}
                          render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                            return (
                              <BaseInput
                                type="phone"
                                onChange={(phone: string) => onChange(phone)}
                                value={value}
                                name={name}
                                placeholder="전화번호"
                              />
                            );
                          }}
                        ></Controller>
                      </div>
                    </td>
                    <td width="240">
                      <div>
                        <Controller
                          control={control}
                          name={`contractContactList.${contactIdx}.email`}
                          render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                            return <BaseInput onChange={onChange} value={value} name={name} placeholder="이메일" />;
                          }}
                        ></Controller>
                      </div>
                    </td>

                    <td width="40">
                      <div className="flex-center-center">
                        {contactIdx < 1 ? (
                          <button
                            className="base-add-btn"
                            onClick={(e) => {
                              e.preventDefault();
                              addContract({
                                name: "",
                                contact: "",
                                email: "",
                                assignedWorkType: "NORMAL_PROVIDE",
                                description: "",
                                isDeleted: false,
                              });

                              const requiredMessage = "필수입력 항목입니다";
                            }}
                            disabled={contractContactList.length === 3}
                          ></button>
                        ) : (
                          <button
                            className="base-erase-btn"
                            onClick={(e) => {
                              e.preventDefault();
                              contractContactList[contactIdx].isDeleted = true;
                              setDeletedContact([...deletedContact, contractContactList[contactIdx]]);
                              remove(contactIdx);
                            }}
                          ></button>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        {errorText(errors)}
      </section>
    </article>
  );
};

export default ContractContactForm;
