import qs from "qs";
import { Link as ReactRouterLink, LinkProps } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { globalPartnerState } from "src/recoil/partners/atom";

function Link(props: LinkProps) {
  const partner = useRecoilValue(globalPartnerState);

  // ======================== 이전 코드 =======================
  // if (typeof props.to === "string") {
  //   const _to = props.to.replace(":partnerId", String(partner?.id));

  //   return <ReactRouterLink {...props} to={_to} />;
  // }

  // ============================ v.1.23 작업 (harry) ============================
  if (typeof props.to === "string") {
    let newTo = "";

    // list 파라미터가 있는지 확인
    const hasListParams = props.to.includes("list=page");

    // list 파라미터가 있는 경우
    if (hasListParams) {
      const currentPathname = props.to.split("?")[0];
      const currentParams = qs.parse(props.to.split("?")[1], { ignoreQueryPrefix: true, allowDots: true });

      const listParams: Record<string, any> = {};
      const otherParams: Record<string, any> = {};
      Object.entries(currentParams).forEach(([key, value]) => {
        if (key.startsWith("list")) {
          listParams[key] = value;
        } else {
          otherParams[key] = value;
        }
      });
      const requestParams = { ...otherParams, ...listParams };

      newTo = currentPathname + "?" + qs.stringify(requestParams, { allowDots: true });
    }

    // list 파라미터가 있으면 newTo, 없으면 props.to 로 이동
    const _to = newTo ? newTo : props.to.replace(":partnerId", String(partner?.id));
    return <ReactRouterLink {...props} to={_to} />;
  }

  return <ReactRouterLink {...props} />;
}
export default Link;
