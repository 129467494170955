import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { providerDetailAsync } from "src/api/provider/provider-api";
import { ProviderDetailModel } from "src/api/provider/provider-types";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import useNavigate from "src/hooks/usePartnerNavigate";
import ProviderAccountDetail from "../../detail/basicInfoDetail/components/ProviderAccountDetail";
import MntTypeAccountForm from "../components/MntTypeAccountForm";
import ProviderTypeAccountForm from "../components/ProviderTypeAccountForm";
import PayletterSection from "./components/PayletterSection";

const ProviderAccountInfo = () => {
  // 로딩바

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const { id } = useParams();
  const providerId: number | undefined = useMemo(
    () => (id ? Number(id) : queryParams?.id ? Number(queryParams.id) : undefined),
    [id, queryParams.id],
  );

  const [providerDetail, setProviderDetail] = useState<ProviderDetailModel>();

  const [isAccountEdit, setIsAccountEdit] = useState(false);

  // 프로바이더 상세
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);

  // 프로바이더 상세 조회 후 setValue 처리
  const providerDetailApi = useCallback(
    async (id: string) => {
      const response = await getProviderDetail({ providerId: id });
      if (response.status >= 200 || response.status <= 299) {
        const detail: ProviderDetailModel = response.data.data;

        setProviderDetail(detail);
      }
    },
    [getProviderDetail],
  );

  useEffect(() => {
    providerDetailApi(String(providerId));
  }, [providerDetailApi, providerId]);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap detail-basic-info">
          {/* 계좌 입금 확인 / 세금계산서 발행 정보 */}
          <article className="contents-container__wrap-article">
            <BaseSectionTitle title="결제 연동 정보" className="" />

            <div className="index-tab">
              <span>결제 서비스 연동 상태</span>
            </div>
            <div className="border-gray py16 pr16 px20">
              <section className="flex-center mb10"></section>
              <section className="flex-center">
                <div className="minmax200 d-flex">
                  <span className="font13 text-gray600">계좌 입금 조회 API: </span>
                  <span className=" font13 ml10">{providerDetail?.provider?.isVerifiedBankAccount ? "연동 성공" : "연동 실패"}</span>
                </div>

                <div className="minmax200">
                  <span className="font13 text-gray600">세금계산서 발행 API: </span>
                  <span className=" font13 ml10">{providerDetail?.provider?.isVerifiedTaxInvoice ? "연동 성공" : "연동 실패"}</span>
                </div>
                <div className="minmax200">
                  <span className="font13 text-gray600">현금영수증 발급 API: </span>
                  <span className=" font13 ml10">{providerDetail?.provider?.isVerifiedCashbill ? "연동 성공" : "연동 실패"}</span>
                </div>
              </section>
            </div>
          </article>
          {/* 계좌 정보 */}
          {isAccountEdit ? (
            <article className="contents-container__wrap-article">
              <>
                {providerDetail?.provider?.supplyType === "RENTAL" && (
                  <ProviderTypeAccountForm
                    providerId={providerId || 0}
                    providerDetail={providerDetail}
                    providerDetailApi={providerDetailApi}
                    onEdit={() => setIsAccountEdit(false)}
                  />
                )}

                {providerDetail?.provider?.supplyType === "MNT" && (
                  <MntTypeAccountForm
                    providerId={providerId || 0} //
                    providerDetail={providerDetail}
                    providerDetailApi={providerDetailApi}
                    onEdit={() => setIsAccountEdit(false)}
                  />
                )}
              </>
            </article>
          ) : (
            <ProviderAccountDetail providerAccount={providerDetail?.providerAccountList} onEdit={() => setIsAccountEdit(true)} />
          )}

          {/* 카드결제 지원 */}
          <PayletterSection
            providerId={providerId || 0}
            providerDetail={providerDetail} //
            providerDetailApi={providerDetailApi}
          />
        </div>
      </div>
    </>
  );
};

export default ProviderAccountInfo;
