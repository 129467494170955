/* eslint-disable array-callback-return */

import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  editTaxInvoiceNumber,
  mntBillSendEmail,
  mntBillUpdate,
  mntGetWeekDay,
  mntSendNts,
  retryPayment,
} from "src/api/billingSchedule/billingSchedule-api";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseInput, BaseModal, BaseTooltip } from "src/components";
import { useToast } from "src/recoil/toast/hook";
import { numberToStringWithComma, ViewYmdFormat, YmdFormat } from "src/utils/common-util";
import PaymentStatusView from "../components/PaymentStatusView";
import StorageProcessingModal from "../modal/StorageProcessing/StorageProcessingModal";
import useContactApi, { objDiff } from "src/pages/contract/hooks/useContractApi";
import { Modal } from "src/api/public-types";
import MakeBillPopup from "../components/MakeBillPopup";

const ScheduleInfoColumns: any = [
  {
    Header: "청구서 명",
    accessor: "bill.billTitle",
    sticky: "left",
    width: 100,
    Cell: ({ value, row, contractBaseInfo, showBtn, callList: getRounds, selectedScheduleId, buildingId }: any) => {
      const { openToast } = useToast();
      //청구서 수정
      const { executeAsync: updateBill } = useApiOperation(mntBillUpdate);
      const [showUpdateBill, setShowUpdateBill] = useState(false);

      const [oriBill, setOriBill] = useState<any>();
      const [bill, setBill] = useState(_.cloneDeep(oriBill));
      const [messageModal, setMessageModal] = useState<Modal>({
        isOpen: false,
      });
      const onClosePopup = useCallback(async () => {
        setShowUpdateBill(false);
        await getRounds(selectedScheduleId);
        // setBill(oriBill);
      }, [getRounds, selectedScheduleId]);

      const validateChange = useCallback(
        (detailList?: any) => {
          let diffKeys: Array<string> = [];
          let check = false;
          if (detailList) {
            const original = _.cloneDeep(oriBill);
            const changed = _.cloneDeep(bill);
            const oDetail: any = _.cloneDeep(original.billDetailList);
            const dl = _.cloneDeep(detailList);
            dl.forEach((detail: any) => {
              delete detail.checkTaxAmount;
              delete detail.isAutoTaxUse;
            });
            check = JSON.stringify(oDetail) !== JSON.stringify(dl);

            diffKeys = objDiff(original, changed);

            return (
              diffKeys.filter(
                (str: string) =>
                  !str.includes("billDetailList") &&
                  !str.includes("baseAmount") &&
                  !str.includes("totalAmount") &&
                  !str.includes("supplyAmount") &&
                  !str.includes("taxAmount"),
              ).length > 0 || check
            );
          }
        },
        [bill, oriBill],
      );

      const onConfirmBill = useCallback(
        async (detailList?: any) => {
          const temp = _.cloneDeep(bill);
          let sendData: any = { billId: bill.billId };

          sendData.isConfirmed = !temp.isConfirmed;
          const pass = validateChange(detailList);

          console.log("pass", pass);
          if (pass === undefined || pass === false) {
            const rnt = await updateBill({ scheduleId: bill.scheduleId, body: sendData });
            if (rnt.status >= 200 && rnt.status < 400) {
              setBill({ ...temp, ...sendData });
            }
            return rnt;
          } else {
            openToast({ content: "변경 사항이 있습니다. 먼저 저장 후 확정 해주세요 ", isSystemToast: true });
            return;
          }
        },
        [bill, updateBill, validateChange, openToast],
      );

      /**
       * 저장 버튼 클릭
       */
      const saveBill = useCallback(
        async (detailList: any) => {
          const temp = _.cloneDeep(bill);

          // console.log("update temp", temp);
          let sendData: any = { billId: oriBill.billId };
          const diffKeys: Array<string> = objDiff(oriBill, bill);
          if (diffKeys.length > 0) {
            for (let i = 0; i < diffKeys.length; i++) {
              const key: string = diffKeys[i];

              if (key !== "billDetailList") {
                sendData[key] = bill[key];
              }
            }
          }
          if (sendData.billStartTime !== "") {
            sendData.billStartTime = moment(temp.billStartTime).format(YmdFormat.WITH_TIME_ZONE);
          }
          if (sendData.billEndTime !== "") {
            sendData.billEndTime = moment(temp.billEndTime).format(YmdFormat.WITH_TIME_ZONE);
          }
          if (sendData.billIssueDate !== "") {
            sendData.billIssueDate = moment(temp.billIssueDate).format(YmdFormat.WITH_TIME_ZONE);
          }
          if (sendData.billDueDate !== "") {
            sendData.billDueDate = moment(temp.billDueDate).format(YmdFormat.WITH_TIME_ZONE);
          }
          if (sendData.pgcode !== "creditcard" && sendData.taxInvoiceIssueDate !== null) {
            sendData.taxInvoiceIssueDate = moment(sendData.taxInvoiceIssueDate).format(YmdFormat.WITH_TIME_ZONE);
          } else {
            delete sendData.taxInvoiceIssueDate;
          }

          temp.billDetailList = detailList;
          setBill(temp);
          //저장 기능 처리
          sendData.billDetailList = detailList;
          delete sendData.billPayStatus;
          delete sendData.createdDate;
          delete sendData.modifiedDate;
          delete sendData.modifiedBy;
          delete sendData.createdBy;

          return await updateBill({ scheduleId: bill.scheduleId, body: sendData });
        },
        [oriBill, bill, updateBill],
      );
      useEffect(() => {
        // console.log("selectedScheduleId", selectedScheduleId, "buildingId", buildingId);
        setOriBill(_.cloneDeep(row.original.bill));
      }, [row.original, selectedScheduleId, buildingId]);

      return (
        <div>
          <div
            className="text-underline pointer"
            onClick={(e: any) => {
              e.preventDefault();
              setBill(_.cloneDeep(row.original.bill));
              setShowUpdateBill(true);
            }}
          >
            <div className="w-100 text-left">{value ? <BaseTooltip contents={value} /> : "-"}</div>
          </div>
          {showUpdateBill && (
            <MakeBillPopup
              isChange={showUpdateBill}
              contractDetail={contractBaseInfo}
              showBtn={showBtn}
              bill={_.cloneDeep(bill)}
              setBill={setBill}
              onClose={onClosePopup}
              onConfirm={onConfirmBill}
              onClick={saveBill}
              messageModal={messageModal}
              setMessageModal={setMessageModal}
              getRounds={getRounds}
              allBill={row.original}
              buildingId={bill.buildingId}
              scheduleId={bill.scheduleId}
            />
          )}
          {messageModal.isOpen && (
            <BaseModal
              isOpen={messageModal.isOpen}
              btnRightTitle={"확인"}
              onClick={async () => {
                setMessageModal({ ...messageModal, isOpen: false });
                await getRounds(bill.scheduleId);
              }}
              title={messageModal.message}
            ></BaseModal>
          )}
        </div>
      );
    },
  },
  {
    Header: "결제수단",
    accessor: "bill.pgcode",
    sticky: "left",
    width: 90,
    Cell: ({ value, row }: any) => {
      return (
        <div>
          {value && value === "virtualaccount" && <p>가상계좌</p>}
          {value && value === "creditcard" && <p>신용카드</p>}
          {value && value === "banktransfer" && <p>계좌이체</p>}
        </div>
      );
    },
  },
  {
    Header: "산출기간",
    accessor: "bill.billStartTime",
    sticky: "left",
    width: 190,
    Cell: ({ value, row }: any) => {
      // console.log("row", row.original);
      const start = moment(row.original.bill.billStartTime).format(ViewYmdFormat.YYYY_MM_DD);
      const end = moment(row.original.bill.billEndTime).format(ViewYmdFormat.YYYY_MM_DD);
      return <p>{start + " - " + end}</p>;
    },
    Footer: ({ rows }: any) => {
      return <p>합계 : </p>;
    },
  },
  {
    Header: () => {
      return (
        <p>
          청구금액
          <br />
          (원)
        </p>
      );
    },
    accessor: "bill.totalAmount",
    sticky: "left",
    width: 120,
    Cell: ({ value }: any) => {
      return <p>{numberToStringWithComma(value)}</p>;
    },
    Footer: ({ rows }: any) => {
      let sum = 0;
      rows.map((row: any) => {
        sum = sum + Number(row.original.bill.totalAmount);
      });
      return <p>{numberToStringWithComma(sum)}</p>;
    },
  },

  {
    Header: "납부 시작일",
    accessor: "bill.billIssueDate",
    // sticky: "left",
    width: 100,
    Cell: ({ value }: any) => {
      return <div>{value ? moment(value).format(ViewYmdFormat.YYYY_MM_DD) : "-"} </div>;
    },
  },
  {
    Header: "납부 종료일",
    accessor: "bill.billDueDate",
    // sticky: "left",
    width: 100,
    Cell: ({ value, row }: any) => {
      let payLimitDay = row.original.bill.billDueDate;
      return <div>{payLimitDay ? moment(payLimitDay, YmdFormat.WITH_TIME_ZONE).format(ViewYmdFormat.FULL) : "-"} </div>;
    },
  },
  {
    Header: "수납확인",
    accessor: "recieve",
    // sticky: "left",
    width: 100,
    Cell: ({ value, row, rows, callList, contractBaseInfo, scheduleId, showBtn }: any) => {
      // const [prevStatus, setPrevStatus] = useState<any>("");
      const [isChange, setIsChange] = useState(false);

      const pDatas = useMemo(() => {
        const datas = {
          row,
          rows,
          callList,
          contractBaseInfo,
          scheduleId,
          showBtn,
          isChange,
          setIsChange,
          type: "schedule",
        };
        return datas;
      }, [callList, contractBaseInfo, isChange, row, rows, scheduleId, showBtn]);

      const checkShowPayBtn = useMemo(() => {
        const limit = moment(row.original.bill.billDueDate, "YYYY-MM-DDTHH:mm:ss.sss+09:00").format(ViewYmdFormat.FULL);
        return moment(limit, ViewYmdFormat.FULL).isAfter(moment(ViewYmdFormat.FULL).format(ViewYmdFormat.FULL));
      }, [row.original.bill]);
      return (
        <div>
          <div>
            {showBtn && row.original.bill.billPayStatus !== "PAYMENT_SUCCESS" ? (
              <BaseButton
                title={row.original.receiptBundle.receiptList.length > 0 ? "수정" : "수납처리"}
                className="color-white size-small"
                disabled={
                  (Number(row.original.bill.totalAmount) > 0 || row.original.bill.billPayStatus === "PAYMENT_READY" ? false : true) || checkShowPayBtn
                }
                onClick={() => setIsChange(true)}
              />
            ) : (
              <BaseButton
                title={"수납확인"}
                className="color-white size-small"
                disabled={
                  (row.original.billReceipt !== null ? false : row.original.bill.billPayStatus === "PAYMENT_SUCCESS" ? false : true) ||
                  checkShowPayBtn
                }
                onClick={() => setIsChange(true)}
              />
            )}
            {isChange && <StorageProcessingModal processData={pDatas} />}
          </div>
        </div>
      );
    },
  },

  {
    Header: "즉시결제",
    accessor: "instantPayment",
    // sticky: "left",
    width: 150,
    Cell: ({ value, row, rows, showBtn, scheduleId, callList, contractBaseInfo, basicInfo }: any) => {
      const [confirmFlag, setConfirmFlag] = useState(false);
      const [prevStatus, setPrevStatus] = useState<any>("");
      const { id } = useParams();
      const { openToast } = useToast();

      const { executeAsync: rtp } = useApiOperation(retryPayment);

      const retryPay = useCallback(async () => {
        setConfirmFlag(false);
        const askReturn: any = await rtp({ billId: Number(row.original.bill.billId) });

        if (askReturn.status >= 200 && askReturn.status <= 299 && String(askReturn.data.data.content.result) === "true") {
          await callList();
          openToast({ content: "즉시결제가 정상처리 되었습니다.", isSystemToast: true });
        } else {
          openToast({ content: "즉시결제가 실패하였습니다. 관리자에게 문의해주세요.", isSystemToast: true });
        }
      }, [callList, openToast, row.original.bill.billId, rtp]);
      const today = moment().format(ViewYmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);

      const checkPrevStatus = useMemo(() => {
        let temp = _.cloneDeep(prevStatus);
        const finded = rows.find((r: any) => Number(r.id) === Number(row.id) - 1);

        if (finded) {
          //console.log(finded.original.bill.billPayStatus);
          temp = finded.original.bill.billPayStatus;
          setPrevStatus(finded.original.bill.billPayStatus);
        }
        return temp;
      }, [prevStatus, row.id, rows]);
      const getBillKey = useMemo(() => {
        let billkey: string | undefined = undefined;
        const find = basicInfo.scheduleList.find((obj: any) => obj.supplyType === "RENTAL");

        if (find) {
          return find.billkey;
        }

        return billkey;
      }, [basicInfo]);

      return (
        <>
          {showBtn ? (
            <>
              <BaseButton
                title={"즉시결제"}
                className="color-white size-small"
                disabled={
                  row.original.bill.pgcode !== "creditcard" ||
                  getBillKey === null ||
                  getBillKey === undefined ||
                  getBillKey === "" ||
                  row.original.receiptBundle.receiptList.length > 0 ||
                  row.original.bill.billPayStatus === "PAYMENT_SUCCESS" ||
                  moment(today).isSameOrBefore(moment(billDate)) ||
                  (row.original.bill.billPayStatus === "PAYMENT_READY" && (checkPrevStatus === "" || checkPrevStatus === "PAYMENT_SUCCESS")
                    ? false
                    : true)
                }
                onClick={() => setConfirmFlag(true)}
              />
              <BaseModal
                title={"즉시 결제하시겠습니까?"}
                isOpen={confirmFlag}
                btnLeftTitle="취소"
                btnRightTitle="확인"
                onClick={() => retryPay()}
                onClose={() => setConfirmFlag(false)}
              ></BaseModal>
            </>
          ) : (
            "-"
          )}
        </>
      );
    },
  },
  {
    Header: "tid",
    accessor: "payment.tid",
    // sticky: "left",
    width: 150,
    Cell: ({ value, row }: any) => {
      let billShowTId = "-";
      const sortedList: any = _.sortBy(row.original.paymentList, ["transactionDate"]).reverse();

      if (sortedList.length > 0) {
        const payment = sortedList[0];

        billShowTId = payment.tid !== "" ? payment.tid : "-";

        if (payment.paymentCancel !== null) {
          billShowTId = payment.paymentCancel.tid;
        }
      }

      return <p>{billShowTId}</p>;
    },
  },
  {
    Header: "연체 기간(일)",
    accessor: "receiptBundle.totalOverdueDays",
    // sticky: "left",
    width: 110,
    Cell: ({ value }: any) => {
      return <div>{value ? value + "일" : "-"} </div>;
    },
  },
  {
    Header: "연체 금액(원)",
    accessor: "receiptBundle.accruedOverdueAmount",
    // sticky: "left",
    width: 110,
    Cell: ({ value, row }: any) => {
      let realtimeOverdueAmount = 0;
      if (row.original.receiptBundle.realtimeOverdueAmount !== undefined) {
        realtimeOverdueAmount += Number(row.original.receiptBundle.realtimeOverdueAmount);
      }

      if (row.original.receiptBundle.accruedOverdueAmount !== undefined) {
        realtimeOverdueAmount += Number(row.original.receiptBundle.accruedOverdueAmount);
      }
      const today = moment().format(ViewYmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{numberToStringWithComma(Number(realtimeOverdueAmount))} </div>;
      } else {
        return <p>-</p>;
      }
    },
  },
  {
    Header: "거래일시",
    accessor: "receiptBundle.lastTrDateTime",
    width: 100,
    Cell: ({ value, row }: any) => {
      return value ? moment(value).format(ViewYmdFormat.FULL) : "-";
    },
  },
  {
    Header: "수납 전 금액",
    accessor: "receiptBundle.billTotalAmount",
    // sticky: "left",
    width: 110,
    Cell: ({ row, value }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{numberToStringWithComma(Number(value))} </div>;
      } else {
        return <p>-</p>;
      }
    },
    Footer: ({ rows }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);

      let sum = 0;
      rows.map((row: any) => {
        const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
        if (moment(today).isSameOrAfter(moment(billDate))) {
          sum = sum + Number(row.original.receiptBundle.billTotalAmount);
        }
      });
      return <p>{numberToStringWithComma(sum) + " 원"}</p>;
    },
  },
  {
    Header: "수납금액",
    accessor: "receiptBundle.receiptTotalAmount",
    // sticky: "right",
    width: 110,
    Cell: ({ row, value }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{numberToStringWithComma(Number(value))} </div>;
      } else {
        return <p>-</p>;
      }
    },
    Footer: ({ rows }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);

      let sum = 0;
      rows.map((row: any) => {
        const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
        if (moment(today).isSameOrAfter(moment(billDate))) {
          sum = sum + Number(row.original.receiptBundle.receiptTotalAmount);
        }
      });
      return <p>{numberToStringWithComma(sum) + " 원"}</p>;
    },
  },
  {
    Header: "잔액",
    accessor: "receiptBundle.billUnpaidAmount",
    // sticky: "right",
    width: 110,
    Cell: ({ row, value }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);
      const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
      if (moment(today).isSameOrAfter(moment(billDate))) {
        return <div>{value > 0 ? "-" + numberToStringWithComma(Number(value)) : "0"} </div>;
      } else {
        return <p>-</p>;
      }
    },
    Footer: ({ rows }: any) => {
      const today = moment().format(ViewYmdFormat.FULL);

      let sum = 0;
      rows.map((row: any) => {
        const billDate = moment(row.original.bill.billIssueDate).format(ViewYmdFormat.FULL);
        if (moment(today).isSameOrAfter(moment(billDate))) {
          sum = sum + Number(row.original.receiptBundle.billUnpaidAmount);
        }
      });
      return <p>{sum > 0 ? "-" + numberToStringWithComma(sum) + " 원" : "0 원"}</p>;
    },
  },
  {
    Header: "상태",
    accessor: "bill.billPayStatus",
    // sticky: "right",
    width: 90,
    Cell: ({ value, row }: any) => {
      // 연체 표시 : -
      let billIssuDay = moment(row.original.bill.billDueDate).format(ViewYmdFormat.YYYY_MM_DD);
      let today = moment().format(ViewYmdFormat.YYYY_MM_DD);

      if (row.original.bill.billPayStatus === "PAYMENT_READY" && moment(today).isAfter(moment(billIssuDay))) {
        value = "PAYMENT_DELAY";
      }

      return <PaymentStatusView value={value} />;
    },
  },
  {
    Header: "승인",
    accessor: "bill.isConfirmed",
    // sticky: "right",
    width: 90,
    Cell: ({ value, row }: any) => {
      return (
        <div className={`${String(value) === "true" ? "chip green" : "chip red"}`}>
          <p>{String(value) === "true" ? "확정" : "미확정"}</p>
        </div>
      );
    },
  },
  {
    Header: "이메일",
    accessor: "bill.billPayStatus.2",
    // sticky: "right",
    width: 90,
    Cell: ({ value, row, showBtn, messageModal, setMessageModal, callList, buildingId, openToast }: any) => {
      const sendHistory = row.original.billEtc.chargeEmailSentAt;
      const { executeAsync: sendEmail } = useApiOperation(mntBillSendEmail);

      const today = moment().toDate();
      const [sendFlag, setSendFlag] = useState(false);
      const [msg, setMsg] = useState("이용료 청구서가 이메일로 납부시작일에 예약 발송됩니다.");
      // const;
      const emailAndChangeBillStatus = useCallback(
        (params: any) => {
          return sendEmail(params);
        },
        [sendEmail],
      );
      const checkIssueDate = useCallback(() => {
        let flag = false;
        if (moment(today).isBefore(moment(row.original.bill.billIssueDate))) {
          // console.log("관리비 청구서가 이메일로 납부시작일에 예약 발송됩니다.");
          setMsg("이용료 청구서가 이메일로 납부시작일에 예약 발송됩니다.");
          flag = false;
        } else if (moment(today).isSame(moment(row.original.bill.billIssueDate))) {
          // console.log("관리비 청구서가 이메일로 즉시 발송됩니다.");
          setMsg("이용료 청구서가 이메일로 즉시 발송됩니다.");
          flag = true;
        } else {
          // console.log("관리비 청구서가 이메일로 즉시 발송됩니다.");
          setMsg("이용료 청구서가 이메일로 즉시 발송됩니다.");
          flag = true;
        }
        return flag;
      }, [row.original.bill.billIssueDate, today]);

      const handleSendMail = useCallback(async () => {
        const flag = checkIssueDate();
        const sendParams: any = { billId: row.original.bill.billId, isSendNow: flag };
        // if (checkIssueDate() === false) {
        //   openToast({ content: "납부시작일에 이메일이 발송됩니다.", isSystemToast: true });
        // }
        const result: any = await emailAndChangeBillStatus(sendParams);
        if (result.status >= 200 && result.status < 400) {
          setMessageModal({ ...messageModal, message: "발송 요청되었습니다.", isOpen: true });
          setSendFlag(false);
          await callList(row.original.bill.scheduleId);
        }
      }, [checkIssueDate, row.original.bill.billId, row.original.bill.scheduleId, emailAndChangeBillStatus, setMessageModal, messageModal, callList]);

      //  청구상태가 ‘저장'이고, 승인이 ‘확정’일때만 버튼 활성화
      return (
        <div>
          <BaseButton
            title={sendHistory === "" ? "발송" : "재발송"}
            className="color-white"
            disabled={row.original.bill.isConfirmed === false}
            onClick={() => {
              console.log("row.original.bill.billId", row.original.bill.billId);
              checkIssueDate();
              setSendFlag(true);
            }}
          />
          {sendFlag && (
            <BaseModal
              isOpen={sendFlag}
              btnLeftTitle={"취소"}
              btnRightTitle={"발송"}
              onClose={() => {
                setSendFlag(false);
              }}
              onClick={handleSendMail}
              title={"발송하시겠습니까?"}
            >
              <p>{sendHistory !== "" ? "이용료 청구서가 이메일로 재발송됩니다" : msg}</p>
            </BaseModal>
          )}
        </div>
      );
    },
  },
  {
    Header: "발송일시",
    accessor: "billEtc.chargeEmailSentAt",
    // sticky: "right",
    width: 90,
    Cell: ({ value, row }: any) => {
      return <p>{value !== "" ? moment(value).format(ViewYmdFormat.FULL) : "-"}</p>;
    },
  },
  {
    Header: "계산서 작성일",
    accessor: "bill.taxInvoiceIssueDate",
    // sticky: "right",
    width: 90,
    Cell: ({ value, row }: any) => {
      return <p>{value !== "" ? moment(value).format(ViewYmdFormat.YYYY_MM_DD) : "-"}</p>;
    },
  },
  {
    Header: "계산서 발행",
    accessor: "bill.billPayStatus.10",
    // sticky: "right",
    width: 90,
    Cell: ({ value, row, showBtn, messageModal, setMessageModal, getRounds, buildingId, openToast }: any) => {
      const { getProviderData } = useContactApi();
      const { executeAsync: sendNts } = useApiOperation(mntSendNts);
      const { executeAsync: getWeekDay } = useApiOperation(mntGetWeekDay);
      const today = moment().toDate();
      const [sendFlag, setSendFlag] = useState(false);
      const [sendFlag2, setSendFlag2] = useState(false);
      const [sendFlag3, setSendFlag3] = useState(false);
      const [msg, setMsg] = useState("");

      // const;
      const invoiceSend = useCallback(
        (params: any) => {
          return sendNts(params);
        },
        [sendNts],
      );
      const checkIssueDate = useCallback(async () => {
        const providerData = await getProviderData(row.original.bill.providerId);
        let type = 1;
        if (
          moment(moment(row.original.bill.taxInvoiceIssueDate).format(ViewYmdFormat.YYYY_MM_DD)).isBefore(
            moment(today).format(ViewYmdFormat.YYYY_MM_DD),
          )
        ) {
          const ym = moment(row.original.bill.taxInvoiceIssueDate).format("YYYY.MM");
          const addMd = moment(ym).add(1, "months").add(10, "days");
          const check = moment(addMd).format(ViewYmdFormat.FULL);

          if (moment(moment(today).format(ViewYmdFormat.YYYY_MM_DD)).isSameOrAfter(moment(check).format(ViewYmdFormat.YYYY_MM_DD))) {
            setMsg(
              "세금계산서/계산서가 즉시 발행됩니다. 선택한 작성일자로 세금계산서 또는 계산서를 발행하면 가산세가 부과될 수 있으니 참고해 주세요",
            );
          } else {
            setMsg("세금계산서/계산서가 즉시 발행됩니다.");
          }
        }
        if (
          moment(moment(row.original.bill.taxInvoiceIssueDate).format(ViewYmdFormat.YYYY_MM_DD)).isSame(
            moment(today).format(ViewYmdFormat.YYYY_MM_DD),
          )
        ) {
          setMsg("세금계산서/계산서가 즉시 발행됩니다.");
        }
        if (
          moment(moment(row.original.bill.taxInvoiceIssueDate).format(ViewYmdFormat.YYYY_MM_DD)).isAfter(
            moment(today).format(ViewYmdFormat.YYYY_MM_DD),
          )
        ) {
          type = 3;
          setMsg("세금계산서/계산서가 계산서 작성일에 예약 발행됩니다.");
        }
        return { passYn: providerData.provider.isVerifiedTaxInvoice, type };
      }, [getProviderData, row.original.bill.providerId, row.original.bill.taxInvoiceIssueDate, today]);

      const handleSendNts = useCallback(async () => {
        const sendParams: any = { billId: row.original.bill.billId };

        const result: any = await invoiceSend(sendParams);
        if (result.status >= 200 && result.status < 400) {
          setMessageModal({ ...messageModal, message: "발행 요청되었습니다.", isOpen: true });
          setSendFlag(false);
          await getRounds(buildingId, row.original.bill.scheduleId);
        }
      }, [row.original.bill.billId, row.original.bill.scheduleId, invoiceSend, setMessageModal, messageModal, getRounds, buildingId]);

      //  청구상태가 ‘저장'이고, 승인이 ‘확정’일때만 버튼 활성화
      return (
        <div>
          <BaseButton
            title={"발행"}
            className="color-white"
            disabled={
              row.original.bill.pgcode === "creditcard" || row.original.bill.billPayStatus === "TEMP_SAVE" || row.original.taxInvoiceList.length > 0
            }
            onClick={async () => {
              const passYn = await checkIssueDate();
              if (passYn.passYn) {
                passYn.type === 1 ? setSendFlag(true) : setSendFlag3(true);
              } else {
                setSendFlag2(true);
              }
            }}
          />
          {sendFlag && (
            <BaseModal
              isOpen={sendFlag}
              btnLeftTitle={"취소"}
              btnRightTitle={"확인"}
              onClose={() => {
                setSendFlag(false);
              }}
              onClick={handleSendNts}
              title={"발행하시겠습니까?"}
            >
              <p>{msg}</p>
            </BaseModal>
          )}
          {sendFlag2 && (
            <BaseModal
              isOpen={sendFlag2}
              btnRightTitle={"확인"}
              onClick={() => {
                setSendFlag2(false);
              }}
              title={"해당 관리처 정보로 계산서를 발행 할 수 없습니다."}
            >
              <p>{"정산정보 메뉴에서 해당 관리처의 팝빌 인증정보 검증을 실행해 주세요."}</p>
            </BaseModal>
          )}
          {sendFlag3 && (
            <BaseModal
              isOpen={sendFlag3}
              btnRightTitle={"확인"}
              onClick={() => {
                setSendFlag3(false);
              }}
              title={"계산서가 예약 발행됩니다."}
            >
              <p>{"청구서에서 설정한 계산서 작성일자에 예약 발행됩니다."}</p>
            </BaseModal>
          )}
        </div>
      );
    },
  },
  {
    Header: () => {
      return (
        <p>
          세금계산서
          <br />
          승인번호
        </p>
      );
    },
    accessor: "TAX_PLUS",
    width: 220,
    Cell: ({ value, row, scheduleId, callList, showBtn }: any) => {
      const [view, setView] = useState(false);
      const [taxInvoice, setTaxInvoice] = useState<any>({ invoiceNumber: "" });
      const [isError, setIsError] = useState(false);
      const [error, setError] = useState("");
      const { id } = useParams();
      const [original, setOriginal] = useState<any>();

      const { executeAsync: changeTaxInvoice } = useApiOperation(editTaxInvoiceNumber);

      useEffect(() => {
        if (row.original.taxInvoiceList !== null && Array.isArray(row.original.taxInvoiceList) && row.original.taxInvoiceList.length > 0) {
          const TAX_PLUS_LIST = row.original.taxInvoiceList.filter((invoice: any) => invoice.invoiceType === "TAX_PLUS");
          // console.log('row.original',row.original)
          const sorted = _.sortBy(TAX_PLUS_LIST, ["createdDate"], ["DESC"]);
          setTaxInvoice(sorted[0]);
          setOriginal(sorted[0]);
        }
        // console.log(showBtn);
      }, [row.original.taxInvoiceList]);

      const saveTaxInvoice = useCallback(async () => {
        const pattern = /[a-zA-Z0-9]{24}/;
        if (taxInvoice.invoiceNumber === "") {
          setError("국세청 승인번호는 필수 입력 정보입니다.");
          setIsError(true);
          return;
        }

        if (pattern.test(taxInvoice.invoiceNumber) === false) {
          setError("올바르지 않은 국세청 승인번호 형식입니다.");
          setIsError(true);
          return;
        }

        const send = _.cloneDeep(taxInvoice);
        send.billId = row.original.bill.billId;
        send.invoiceType = "TAX_PLUS";

        const response = await changeTaxInvoice({ scheduleId, data: send });

        if (response.status > 199 && response.status < 300) {
          setView(false);
          setError("국세청 승인번호가 변경되었습니다.");
          setIsError(true);
          await callList();
        }
      }, [callList, changeTaxInvoice, row.original.bill.billId, scheduleId, taxInvoice]);

      return (
        <div className="d-flex">
          <div className="flex-center">
            <div className="w-100 text-left mr10">
              {taxInvoice && taxInvoice.invoiceNumber !== undefined && taxInvoice.invoiceNumber !== "" ? (
                <div>
                  <BaseTooltip contents={taxInvoice.invoiceNumber} isSingleLine={true} />
                  {showBtn && (
                    <BaseButton
                      title="수정"
                      disabled={Number(row.original.bill.totalAmount) === 0}
                      className="color-white size-small"
                      onClick={() => setView(true)}
                    />
                  )}
                </div>
              ) : (
                "-"
              )}
            </div>
          </div>

          <BaseModal
            className="dialog-modal small-size"
            isOpen={view}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClose={() => {
              setView(false);

              setTaxInvoice(original);
            }}
            onClick={() => saveTaxInvoice()}
          >
            <>
              <div className="modal-title border-bottom">
                <div className="left-area align-items-center">
                  <span>세금계산서 발행 정보 수정</span>
                </div>
                <div className="right-area"></div>
              </div>
              <div className="contents-scroll">
                <table className="inner-table" width="100%">
                  <tbody>
                    <tr>
                      <td colSpan={2} className="text-left font12 text-gray500">
                        <p>* 팝빌에서 자동 발행 된 ‘국세청승인번호’ 가 출력됩니다.</p>
                        <p>* 홈택스를 통해 발행/재발행 시 ‘국세청승인번호(24자리)’ 를 등록 해주세요.</p>
                      </td>
                    </tr>

                    <tr>
                      <td width="180">
                        <div className="flex-center ">
                          <span className="text-gray600">국세청 승인번호</span>
                        </div>
                      </td>
                      <td>
                        <div className="py10">
                          <div className="minmax300 mb8">
                            <BaseInput
                              name={"taxInvoiceNumber"}
                              value={String(taxInvoice?.invoiceNumber)}
                              onChange={(value: string) => {
                                const temp = _.cloneDeep(taxInvoice);
                                temp.invoiceNumber = value;
                                setTaxInvoice(temp);
                              }}
                              maxLength={24}
                              // disabled={true}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          </BaseModal>
          <BaseModal isOpen={isError} btnRightTitle="확인" onClick={() => setIsError(false)}>
            <p>{error}</p>
          </BaseModal>
        </div>
      );
    },
  },
  {
    Header: () => {
      return (
        <p>
          세금계산서
          <br />
          전송일자
        </p>
      );
    },
    accessor: "taxInvoiceIssueDate",
    // sticky: "left",
    width: 150,
    Cell: ({ value, row }: any) => {
      const [original, setOriginal] = useState<any>();
      useEffect(() => {
        if (row.original.taxInvoiceList !== null && Array.isArray(row.original.taxInvoiceList) && row.original.taxInvoiceList.length > 0) {
          const TAX_PLUS_LIST = row.original.taxInvoiceList.filter((invoice: any) => invoice.invoiceType === "TAX_PLUS");
          // console.log('row.original',row.original)
          const sorted = _.sortBy(TAX_PLUS_LIST, ["createdDate"], ["DESC"]);

          setOriginal(sorted[0]);
        }
        // console.log(showBtn);
      }, [row.original.taxInvoiceList]);
      return <p>{original && original?.createdDate !== "" ? moment(original?.createdDate).format(ViewYmdFormat.FULL) : "-"}</p>;
    },
  },
  {
    Header: () => {
      return (
        <p>
          계산서
          <br />
          승인번호
        </p>
      );
    },
    accessor: "TAX_NONE",
    width: 220,
    Cell: ({ value, row, scheduleId, callList, showBtn }: any) => {
      const [view, setView] = useState(false);
      const [taxInvoice, setTaxInvoice] = useState<any>({ invoiceNumber: "" });
      const [isError, setIsError] = useState(false);
      const [error, setError] = useState("");
      const { id } = useParams();
      const [original, setOriginal] = useState<any>();

      const { executeAsync: changeTaxInvoice } = useApiOperation(editTaxInvoiceNumber);

      useEffect(() => {
        if (row.original.taxInvoiceList !== null && Array.isArray(row.original.taxInvoiceList) && row.original.taxInvoiceList.length > 0) {
          const TAX_PLUS_LIST = row.original.taxInvoiceList.filter((invoice: any) => invoice.invoiceType !== "TAX_PLUS");

          const sorted = _.sortBy(TAX_PLUS_LIST, ["createdDate"], ["DESC"]);
          setTaxInvoice(sorted[0]);
          setOriginal(sorted[0]);
        }
        // console.log(showBtn);
      }, [row.original.taxInvoiceList]);

      const saveTaxInvoice = useCallback(async () => {
        const pattern = /[a-zA-Z0-9]{24}/;
        if (taxInvoice.invoiceNumber === "") {
          setError("국세청 승인번호는 필수 입력 정보입니다.");
          setIsError(true);
          return;
        }

        if (pattern.test(taxInvoice.invoiceNumber) === false) {
          setError("올바르지 않은 국세청 승인번호 형식입니다.");
          setIsError(true);
          return;
        }

        const send = _.cloneDeep(taxInvoice);
        send.billId = row.original.bill.billId;
        send.invoiceType = "TAX_NONE";

        const response = await changeTaxInvoice({ scheduleId, data: send });

        if (response.status > 199 && response.status < 300) {
          setView(false);
          setError("국세청 승인번호가 변경되었습니다.");
          setIsError(true);
          await callList();
        }
      }, [callList, changeTaxInvoice, row.original.bill.billId, scheduleId, taxInvoice]);

      return (
        <div className="d-flex">
          <div className="flex-center">
            <div className="w-100 text-left mr10">
              {taxInvoice && taxInvoice.invoiceNumber !== undefined && taxInvoice.invoiceNumber !== "" ? (
                <>
                  <BaseTooltip contents={taxInvoice.invoiceNumber} isSingleLine={true} />
                  {showBtn && (
                    <BaseButton
                      title="수정"
                      disabled={Number(row.original.bill.totalAmount) === 0}
                      className="color-white size-small"
                      onClick={() => setView(true)}
                    />
                  )}
                </>
              ) : (
                "-"
              )}
            </div>
          </div>

          <BaseModal
            className="dialog-modal small-size"
            isOpen={view}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClose={() => {
              setView(false);

              setTaxInvoice(original);
            }}
            onClick={() => saveTaxInvoice()}
          >
            <>
              <div className="modal-title border-bottom">
                <div className="left-area align-items-center">
                  <span>계산서 발행 정보 수정</span>
                </div>
                <div className="right-area"></div>
              </div>
              <div className="contents-scroll">
                <table className="inner-table" width="100%">
                  <tbody>
                    <tr>
                      <td colSpan={2} className="text-left font12 text-gray500">
                        <p>* 팝빌에서 자동 발행 된 ‘국세청승인번호’ 가 출력됩니다.</p>
                        <p>* 홈택스를 통해 발행/재발행 시 ‘국세청승인번호(24자리)’ 를 등록 해주세요.</p>
                      </td>
                    </tr>

                    <tr>
                      <td width="180">
                        <div className="flex-center ">
                          <span className="text-gray600">국세청 승인번호</span>
                        </div>
                      </td>
                      <td>
                        <div className="py10">
                          <div className="minmax300 mb8">
                            <BaseInput
                              name={"taxInvoiceNumber"}
                              value={String(taxInvoice?.invoiceNumber)}
                              onChange={(value: string) => {
                                const temp = _.cloneDeep(taxInvoice);
                                temp.invoiceNumber = value;
                                setTaxInvoice(temp);
                              }}
                              maxLength={24}
                              // disabled={true}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          </BaseModal>
          <BaseModal isOpen={isError} btnRightTitle="확인" onClick={() => setIsError(false)}>
            <p>{error}</p>
          </BaseModal>
        </div>
      );
    },
  },
  {
    Header: () => {
      return (
        <p>
          계산서
          <br />
          전송일자
        </p>
      );
    },
    accessor: "cashBillIssueDate",
    // sticky: "left",
    width: 150,
    Cell: ({ value, row, showBtn }: any) => {
      const [original, setOriginal] = useState<any>();
      useEffect(() => {
        if (row.original.taxInvoiceList !== null && Array.isArray(row.original.taxInvoiceList) && row.original.taxInvoiceList.length > 0) {
          const TAX_PLUS_LIST = row.original.taxInvoiceList.filter((invoice: any) => invoice.invoiceType !== "TAX_PLUS");
          // console.log('row.original',row.original)
          const sorted = _.sortBy(TAX_PLUS_LIST, ["createdDate"], ["DESC"]);

          setOriginal(sorted[0]);
        }
        // console.log(showBtn);
      }, [row.original.taxInvoiceList]);
      return <p>{original && original?.createdDate !== "" ? moment(original?.createdDate).format(ViewYmdFormat.FULL) : "-"}</p>;
    },
  },
];
export default ScheduleInfoColumns;
