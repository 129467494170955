import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ProviderPayletterAddModel, ProviderPayletterModel } from "src/api/provider/provider-types";
import { BaseButton, BaseInput, BaseModal, BaseToggle, BaseTooltip } from "src/components";
import { Modal } from "src/pages/product/product-types";
import { useToast } from "src/recoil/toast/hook";
import BasicPayletterStatus from "../../detail/basicInfoDetail/components/BasicPayletterStatus";

type Props = {
  providerId: number;
  payletter?: ProviderPayletterModel;
  providerDetailApi: (providerId: string) => Promise<void>;
  editProviderPayletter: Function;
  autoPayletter?: ProviderPayletterModel;
  isEdit: () => void;
};

const BasicPayletter = ({
  providerId,
  payletter, //
  providerDetailApi,
  editProviderPayletter,
  autoPayletter,
  isEdit,
}: Props) => {
  // 토스트
  const { openToast } = useToast();

  const [isBasicPayletter, setIsBasicPayletter] = useState(true);

  const [submitData, setSubmitData] = useState<ProviderPayletterAddModel>();

  // 저장 컨펌 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // 경고창 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // useForm default
  const defaultValues = useMemo(() => {
    const providerPostData: ProviderPayletterAddModel = {
      payletterList: {
        payletterId: undefined,
        clientId: "",
        paymentKey: "",
        searchKey: "",
        clientType: "NORMAL",
      },
    };

    return providerPostData;
  }, []);

  const { control, setValue, register, handleSubmit } = useForm<ProviderPayletterAddModel>({
    defaultValues,
  });

  // 프로바이더 상세에서 페이레터 정보 setValue
  const onSetValuePayletter = useCallback(() => {
    if (payletter) setValue("payletterList", payletter);
  }, [payletter, setValue]);

  useEffect(() => {
    onSetValuePayletter();
  }, [onSetValuePayletter]);

  // 페이레터 유효성 검사
  const formValidation = useCallback(() => {
    // 카드결제 지원 (payletter)
    if (isBasicPayletter) {
      register("payletterList.clientId", {
        validate: (value: string) => {
          if (!value) return "가맹점 아이디를 입력해주세요.";
          const alphanumericRegex = /^[A-Za-z0-9]+$/;
          if (!alphanumericRegex.test(value)) {
            return "가맹점 아이디는 알파벳과 숫자로만 입력할 수 있습니다.";
          }
        },
      });
      register("payletterList.paymentKey", {
        required: { value: true, message: "결제 API Key를 입력해주세요." },
      });
      register("payletterList.searchKey", {
        required: { value: true, message: "조회 API Key를 입력해주세요." },
      });
    }
  }, [isBasicPayletter, register]);

  useEffect(() => {
    formValidation();
  }, [formValidation]);

  // 프로바이더 페이레터 등록/수정 api
  const updatePayletterApi = async (data?: ProviderPayletterAddModel) => {
    let editData = { providerId, payletterList: [data?.payletterList] };

    // 토글이 false 면 일반/자동결제 모두 지우기
    if (!isBasicPayletter && autoPayletter) {
      editData = { providerId, payletterList: [{ ...data?.payletterList }, { ...autoPayletter, isDeleted: true }] as ProviderPayletterModel[] };
    }

    const { status } = await editProviderPayletter(editData);
    if (status >= 200 || status <= 299) {
      await providerDetailApi(String(providerId));
      setConfirmModal({ isOpen: false });
      isEdit();
      openToast({ content: `정상적으로 수정되었습니다.` });
    }
  };

  //  유효성 검사후 저장
  const onSubmit = async (data?: ProviderPayletterAddModel, e?: any) => {
    e.preventDefault();
    setSubmitData(data);
    setConfirmModal({ isOpen: true, message: isBasicPayletter ? "저장하시겠습니까?" : "등록된 카드 일반결제 및 자동결제를 해제하시겠습니까?" });
  };

  const onError = useCallback((errors: any, e?: any) => {
    console.log("onError errors", errors);
    return false;
  }, []);

  return (
    <article className="contents-container__wrap-article ">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__sub-title flex-column">
          <div className="flex-center-between w-100">
            <div className="flex-center">
              <h2 className="">신용카드 일반결제</h2>
              <div className="ml10 mr15">
                <BaseTooltip touchIcon="QUESTION" className=" ">
                  <ul className="pl20">
                    <li className="mb5">{`일반결제는 한 번의 거래로 결제가 완료되는 방식입니다.`}</li>
                    <li>
                      신용카드 일반결제를 제공하려면{" "}
                      <a href="https://www.payletter.com/ko/service/domestic" target={"_blank"} className="text-blue px5" rel="noreferrer">
                        페이레터
                      </a>
                      의 전자결제서비스에 가입 후 연동을 완료해주세요.
                    </li>
                  </ul>
                </BaseTooltip>
              </div>
              <BaseToggle
                onChange={(checked: boolean) => {
                  setValue("payletterList.isDeleted", !checked);
                  setIsBasicPayletter(checked);
                }}
                checked={isBasicPayletter}
              />
            </div>
            <div className="flex-center">
              <BaseButton title="취소" className="color-white mr10" onClick={isEdit} />
              <BaseButton type="submit" title="저장" />
            </div>
          </div>
        </div>
        {isBasicPayletter && (
          <div className="mb10">
            <div className="index-tab">
              <span>일반결제 API 연동 설정</span>
            </div>
            <div className="border-gray py16 pr16 px20">
              <section className="contents-container__grid ">
                <div className="contents-container__grid-index">
                  <p className="required">가맹점 아이디</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <Controller
                      control={control}
                      name={`payletterList.clientId`}
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <BaseInput
                            onChange={onChange}
                            value={value}
                            name={name}
                            errorText={error && error.message}
                            placeholder="페이레터에서 발급받은 Merchant ID를 입력해주세요"
                          />
                        );
                      }}
                    ></Controller>
                  </div>
                </div>
              </section>

              <section className="contents-container__grid ">
                <div className="contents-container__grid-index">
                  <p className="required">결제 API Key</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <Controller
                      control={control}
                      name={`payletterList.paymentKey`}
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <BaseInput
                            onChange={onChange}
                            value={value}
                            name={name}
                            errorText={error && error.message}
                            type="password"
                            placeholder="페이레터에서 발급받은 Payment API Key를 입력해주세요"
                          />
                        );
                      }}
                    ></Controller>
                  </div>
                </div>
              </section>

              <section className="contents-container__grid ">
                <div className="contents-container__grid-index">
                  <p className="required">조회 API Key</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <Controller
                      control={control}
                      name={`payletterList.searchKey`}
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <BaseInput
                            onChange={onChange}
                            value={value}
                            name={name}
                            errorText={error && error.message}
                            type="password"
                            placeholder="페이레터에서 발급받은 Search API Key를 입력해주세요"
                          />
                        );
                      }}
                    ></Controller>
                  </div>
                </div>
              </section>
            </div>
            <div className="mb10">
              <BasicPayletterStatus payletter={payletter} />
            </div>
          </div>
        )}
      </form>

      <BaseModal
        isOpen={confirmModal.isOpen}
        btnRightTitle="확인"
        btnLeftTitle="취소"
        title={confirmModal.message}
        onClose={() => setConfirmModal({ isOpen: false })}
        onClick={() => {
          (submitData || confirmModal.payload) && updatePayletterApi(submitData);
        }}
      ></BaseModal>
      <BaseModal
        isOpen={alertModal.isOpen}
        btnRightTitle="확인"
        title={!alertModal.payload ? alertModal.message : ""}
        onClick={() => {
          setAlertModal({ isOpen: false, payload: "" });
        }}
      >
        {alertModal.payload === "error" && (
          <div>
            <p className="font18 font-weight-semibold text-gray900">결제 검증에 실패하였습니다.</p>
            <p className="my20">검증 중 문제가 발생하였습니다.</p>
            <p className="pa8 text-left" style={{ backgroundColor: "#F2F4F6", maxHeight: 250, overflow: "hidden", textOverflow: "ellipsis" }}>
              {alertModal.message}
            </p>
          </div>
        )}
      </BaseModal>
    </article>
  );
};

export default BasicPayletter;
